<script>
import { defineComponent } from 'vue'
import { useLayout } from 'src/composables/useLayout'

export default defineComponent({
  name: 'CountrySelector',
  props: ['dense', 'header'],
  setup () {
    const { country, countries } = useLayout()
    return { country, countries }
  }
})
</script>

<template lang="pug">
q-item.row.cursor-pointer.justify-between(dense :class="dense ? 'justify-center items-center' : 'items-center box-shadow'" :style="`width: ${dense ? '200px' : '100%'}; ${dense ? `background: F2F2F2; height: ${header ? 32 : 45}px; border-radius: 7px;` : 'height: 62px; background: #FFFFFF; border-radius: 7px;'}`" v-if="country")
  .row.no-wrap.items-center
    svg(xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width="28px" height="28px" viewBox='0 0 1200 600' version='1.1')
      path(style=' stroke:none;fill-rule:nonzero;fill:rgb(81.199646%,12.5%,15.699768%);fill-opacity:1;' d='M 0 0 L 1200 0 L 1200 200 L 0 200 L 0 0 ')
      path(style=' stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;' d='M 0 200 L 1200 200 L 1200 400 L 0 400 L 0 200 ')
      path(style=' stroke:none;fill-rule:nonzero;fill:rgb(2.69928%,52.89917%,31.799316%);fill-opacity:1;' d='M 0 400 L 1200 400 L 1200 600 L 0 600 L 0 400 ')
    .text-uppercase.q-ml-sm(:style="$q.screen.gt.sm ? `color: #626262; font-size: 14px; font-weight: 500` : `color: ${dense ? '#43464B' : '#4D4F53'}; font-size: 16px; font-weight: 500;`") {{ country.name }} - {{ country.currency }}
  .flex.flex-center(v-if="countries.length > 0")
    svg(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24' fill="#4D4F53")
      path(fill='none' d='M0 0h24v24H0z')
      path(d='M12 16l-6-6h12z')
  q-menu(v-if="countries.length > 0" fit)
    .full-width.bg-white
      .row.no-wrap.items-center(v-for="item in countries" v-close-popup).q-pa-sm
        svg(xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width="28px" height="28px" viewBox='0 0 1200 600' version='1.1')
          path(style=' stroke:none;fill-rule:nonzero;fill:rgb(81.199646%,12.5%,15.699768%);fill-opacity:1;' d='M 0 0 L 1200 0 L 1200 200 L 0 200 L 0 0 ')
          path(style=' stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;' d='M 0 200 L 1200 200 L 1200 400 L 0 400 L 0 200 ')
          path(style=' stroke:none;fill-rule:nonzero;fill:rgb(2.69928%,52.89917%,31.799316%);fill-opacity:1;' d='M 0 400 L 1200 400 L 1200 600 L 0 600 L 0 400 ')
        .text-uppercase.q-ml-sm(:style="$q.screen.gt.sm ? `color: #626262; font-size: 14px; font-weight: 500` : `color: ${dense ? '#43464B' : '#4D4F53'}; font-size: 16px; font-weight: 500;`") {{ item.name }} - {{ item.currency }}
</template>

<style scoped lang="sass">
.topHeaderText
  font-weight: 400
  font-size: 13px
  line-height: 1.25rem
  letter-spacing: .2px
  color: #626262
</style>
