<script>
import { useLayout } from 'src/composables/useLayout'
import { useRoute, useRouter } from 'vue-router'
import { useFilters } from 'src/composables/useFilters'
import S3Image from 'components/general/S3Image'
import { useCart } from 'src/composables/useCart'

export default {
  name: 'ProductGiftItem',
  components: { S3Image },
  props: ['gift', 'showPrice', 'popupItem', 'smallCartItem', 'isOrder'],
  emits: ['clicked'],
  setup (props, { emit }) {
    const { translate } = useLayout()
    const router = useRouter()
    const route = useRoute()
    const { prettifyCurrency } = useFilters()
    const { removeProduct } = useCart()

    function openItem () {
      if (route.path !== `/termek/${props.gift.product.url}`) {
        router.push(`/termek/${props.gift.product.url}`)
      }
      if (props.smallCartItem) {
        emit('clicked')
      }
    }

    const removeCartItemFromCart = () => {
      removeProduct({ product: props.gift.product.product_code, type: 'GIFT', mainProduct: props.gift.mainProduct ? props.gift.mainProduct._id : null })
    }

    return {
      translate,
      openItem,
      prettifyCurrency,
      removeCartItemFromCart
    }
  }
}
</script>

<template lang="pug">
.full-width
  .q-mt-sm(v-if="popupItem" style="position: relative;")
    .row.flex.items-center.no-wrap.q-pb-sm.popup-item
      .row.no-wrap.row.items-center
        .flex.flex-center.cursor-pointer(@click="openItem" style="background: #DFE0E3;" :class="smallCartItem && $q.screen.lt.sm ? 'q-mr-sm' : 'q-mr-md'" :style="$q.screen.lt.md || smallCartItem ? 'height: 90px; width: 90px;' : 'height: 100px; width: 100px;'")
          div
            s3-image(:ratio="1" :src="gift.product.img[0]" img-style="width:90px; height: 90px;")
            q-tooltip {{translate('product.openItem')}}
      .row.items-center.justify-between.q-mr-md(v-if="$q.screen.gt.sm && !smallCartItem")
        .row.items-center
          .row.items-center.no-wrap
            q-chip.quantityCircle {{ gift.quantity + translate('product.piece')}}
      .row.flex-1.full-width.no-wrap
        .row.justify-between.items-center.no-wrap.full-width
          .row.items-center.full-width(:class="smallCartItem ? 'q-pr-sm q-pl-xs' : ''")
            .productGiftName(v-if="!smallCartItem" @click="openItem" :style="$q.screen.lt.sm ? 'font-weight: 400' : ''") {{ gift.product.product_name }}
            .productGiftName(v-else @click="openItem") {{ gift.product.product_name }}
            .row.items-center.full-width(v-if="$q.screen.lt.md || smallCartItem" :class="smallCartItem ? 'justify-between no-wrap' : ''")
              .row.items-center
                .column.justify-center.no-wrap
                  .cart-item-quantity(v-if="smallCartItem") {{gift.product.packaging}}
                  .cart-item-taste(v-if="smallCartItem") {{gift.product.taste}}
              .no-wrap.lt-md(:class="smallCartItem ? 'column items-end self-end' : 'row items-center q-pt-xs'" style="min-width: fit-content")
                .normalPrice(v-if="gift.product && smallCartItem" :style="smallCartItem ? 'font-size: 15px !important; font-weight: 400;' : ''") {{ gift.quantity }}db
                .normalPrice(v-if="gift.product" :style="smallCartItem ? 'font-size: 15px !important; font-weight: 600' : 'font-size: 17px;'") {{ prettifyCurrency(gift.prices.gross_price * gift.quantity) }}
          .row.justify-end.no-wrap.q-pr-sm.items-center(v-if="$q.screen.gt.sm && !smallCartItem" style="min-width: fit-content")
            .normalPrice.q-pl-xs(v-if="gift") {{ prettifyCurrency((gift.prices.gross_price * gift.quantity), 0) }}
          .no-wrap.gt-sm.q-pr-sm.full-height(v-if="smallCartItem" :class="smallCartItem ? 'column justify-center' : 'row justify-end items-center'" style="min-width: fit-content")
            .small-cart-quantity(v-if="smallCartItem") {{ gift.quantity }}db
            .normalPrice.text-strike(style="line-height: 18px; font-size: 14px !important" :class="smallCartItem ? '' : 'q-pr-sm'") {{ prettifyCurrency((gift.product.prices.gross_price * gift.quantity), 0) }}
            .normalPrice.red-color(style="line-height: 18px;" :class="smallCartItem ? '' : 'q-pr-sm'") {{ prettifyCurrency((gift.prices.gross_price * gift.quantity), 0) }}
        .flex.flex-center.lt-sm(v-if="!smallCartItem")
          q-chip.dark-color(style="font-weight: 500;") {{ gift.quantity }}db
    q-separator(size="1px")

  div(v-else)
    q-item.full-width.row.justify-between.box-shadow(clickable :ripple="false" flat :class="$q.screen.lt.sm ? 'q-my-sm' : 'q-py-sm'" :style="`position: relative; height: ${$q.screen.lt.sm ? '70px;' : '84px;'} border-radius: 7px; background: #ECECEE;`" v-if="!showPrice")
      .row.no-wrap
        s3-image.q-mr-sm(:ratio="1" :src="gift.product.img" img-style="width: 50px; height: 50px;")
        .row.items-center(@click="openItem; $emit('openGiftSelector')")
          .dark-color.productPageName
            span.text-600.q-pr-xs(style="color: #f1222a") {{ gift.quantity + translate('products.piece') }}
            span {{ gift.product.product_name }}
      .flex.flex-center(@click="$emit('remove', gift)")
        svg(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='28' height='28' fill="#A4A4A6")
          path(fill='none' d='M0 0h24v24H0z')
          path(d='M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z')

    .full-width(v-else)
      .row.flex.relative-position(v-if="isOrder")
        .no-wrap.row.items-center
          .flex.flex-center.q-mr-md.cursor-pointer(@click="openItem" style="background: #DFE0E3; margin-right: 16px;" :style="$q.screen.lt.md ? 'height: 80px; width: 80px;' : 'height: 90px; width: 90px;'")
            div
              s3-image(:ratio="1" :src="gift.product.img" :img-style="$q.screen.lt.md ? 'width: 70px; height: 70px;' : 'width: 85px; height: 85px;'")
              q-tooltip {{translate('product.openItem')}}
        .flex-2(style="display: flex; flex-direction: column; justify-content: center;")
          .row.items-center.no-wrap(:style="$q.screen.lt.md ? 'width: 75%' : 'width: 95%'")
            .row
              .dark-color.text-600.cursor-pointer(@click="openItem" :style="`font-size: ${$q.screen.lt.sm ? '14px' : '20px'}; line-height: 16px;`") {{ gift.product.product_name }}
            .row.extra-product.cursor-pointer.self-start.gt-sm(@click="$emit('showSelector')")
              .extra-product-text
                | {{ translate('product.extraProduct') }}
          .row.extra-product.cursor-pointer.self-start.lt-md.q-mt-xs(@click="$emit('showSelector')")
            .extra-product-text
              | {{ translate('product.extraProduct') }}
          .row.justify-between.lt-md
            .column
              .dark-color
                | {{ gift.product.taste }}
          .row.justify-between.gt-sm
            .column(v-if="gift.product.categories && gift.product.categories[0]")
              .row.full-width.dark-color(style="font-size: 18px;")
                | {{ gift.product.categories[0].name }}
        .row.gt-sm.justify-end.items-center
          .column.items-end.justify-center(style="line-height: 24px; min-width: 100px; margin-left: 10px;")
            .dark-color(style="font-size: 18px;") {{gift.quantity + translate('product.piece')}}
            .dark-color.text-600.text-strike(style="font-size: 20px;") {{ prettifyCurrency((gift.product.prices.gross_price * gift.quantity), 0) }}
            .text-600.red-color(style="font-size: 20px;") {{ prettifyCurrency((gift.prices.gross_price * gift.quantity), 0) }}
        .lt-md.no-wrap.column.justify-center.items-end
          .text-600.text-strike.dark-color(style="font-size: 17px;") {{ prettifyCurrency((gift.product.prices.gross_price * gift.quantity), 0) }}
          .text-600.red-color(style="font-size: 17px;") {{ prettifyCurrency((gift.prices.gross_price * gift.quantity), 0) }}

      .row.full-width.relative-position(v-else :style="$q.screen.lt.md ? 'padding-bottom: 12px' : ''")
        .row.full-width.relative-position
          .row.full-width
            .flex.flex-center.cursor-pointer(@click="openItem" style="background: #DFE0E3;" :style="$q.screen.lt.md ? 'height: 90px; width: 90px;  margin-right: 10px;' : 'height: 120px; width: 120px;  margin-right: 26px;'")
              div
                s3-image(:ratio="1" :src="gift.product.img" :img-style="$q.screen.lt.md ? 'width: 90px; height: 90px;' : 'width: 115px; height: 115px;'")
            .flex-2(style="display: flex; flex-direction: column; justify-content: center;")
              .absolute-top-right.row.items-center
                .cursor-pointer.gt-sm(@click="$emit('remove', gift)")
                  svg(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24' fill="#CCCCCC")
                    path(fill='none' d='M0 0h24v24H0z')
                    path(d='M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z')
                  q-tooltip {{ translate('cart.removeItem') }}
                q-btn.lt-md.flex.flex-center.q-pa-sm.box-shadow(@click="$emit('showSelector')" style="background: #ECECEE; border-radius: 7px;" dense flat)
                  svg(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24' fill="#A5A5A5")
                    path(fill='none' d='M0 0h24v24H0z')
                    path(d='M6.414 16L16.556 5.858l-1.414-1.414L5 14.586V16h1.414zm.829 2H3v-4.243L14.435 2.322a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414L7.243 18zM3 20h18v2H3v-2z')
              .row.items-center(:style="$q.screen.lt.md ? 'width: 75%' : 'width: 95%'")
                .row(:class="$q.screen.lt.md ? '' : 'q-mb-xs'")
                  .dark-color.text-600.cursor-pointer(:style="$q.screen.lt.sm ? 'font-size: 14px; line-height: 1.3 !important' : 'line-height: 20px; font-size: 20px; padding-right: 6px;'" @click="openItem") {{ gift.product.product_name }}
                .row.extra-product.self-start.gt-sm(@click="$emit('showSelector')")
                  .extra-product-text
                    | {{ translate('product.extraProduct') }}
              .row.justify-between.lt-md
                .column
                  .dark-color.q-pt-xs
                    | {{ gift.product.taste }}
                  .row.extra-product.self-start.lt-md.q-mt-xs(@click="$emit('showSelector')")
                    .extra-product-text
                      | {{ translate('product.extraProduct') }}
              .row.justify-between.gt-sm
                .column(v-if="gift.product.categories && gift.product.categories[0]")
                  .row.full-width.dark-color(style="font-size: 18px;")
                    | {{ gift.product.categories[0].name }}
            .row.gt-sm.justify-end.items-end(style="width: 100px;")
              .column.items-end.justify-center(style="line-height: 20px; min-width: 100px; margin-right: 7px;")
                .text-400(style="font-size: 20px; color: #323232;") {{ gift.quantity }}db
                .text-500.text-strike(style="font-size: 20px; color: #323232;") {{ prettifyCurrency((gift.product.prices.gross_price * gift.quantity), 0) }}
                .text-500.red-color(style="font-size: 20px;") {{ prettifyCurrency((gift.prices.gross_price * gift.quantity), 0) }}
          .row.justify-between.items-center.lt-md.full-width.q-pt-xs
            .row.no-wrap
              .dark-color.text-no-wrap(style="font-size: 18px;") {{ gift.quantity + translate('product.piece')}}
            .row.no-wrap(v-if="gift.product")
              .text-500.dark-color.text-strike.q-pr-sm(style="font-size: 18px;") {{ prettifyCurrency((gift.product.prices.gross_price * gift.quantity), 0) }}
              .text-500.red-color(style="font-size: 18px;") {{ prettifyCurrency((gift.prices.gross_price * gift.quantity), 0) }}
</template>

<style lang="sass" scoped>
.extra-product
  cursor: pointer
  margin-right: 10px
  background: linear-gradient(90deg, #E5F0FE, #FFE8E8)
  .extra-product-text
    font-weight: 700
    text-transform: uppercase
    white-space: nowrap
    padding: 2px 8px
    background: -webkit-linear-gradient(0deg, #0370F7, #FB2C2F 80%)
    -webkit-background-clip: text
    -webkit-text-fill-color: transparent
    font-size: 14px
    @media screen and (max-width: 1000px)
      font-size: 12px
.chip
  border: 2px solid rgb(50, 50, 50)
  background: transparent !important
.chip-sm
  border: 1px solid rgb(50, 50, 50)
  background: transparent !important
.productGiftName
  font-size: 16px
  font-weight: 500
  color: #4D4F53
  @media screen and (min-width: 104px)
    line-height: 17px !important
  @media screen and (max-width: 1023px)
    line-height: 1.3 !important
.quantityCircle
  border: 2px solid #55575B
  border-radius: 56px
  background: white
  padding: 5px 10px 5px 10px
  font-size: 16px
  display: flex
  justify-content: center
  align-items: center
  color: #55575B
  font-weight: 500
.normalPrice
  font-size: 16px !important
  color: #4d4f53
  font-weight: 500
  text-align: right
.productPageName
  font-size: 16px
  line-height: 1.3 !important
.small-cart-quantity
  color: #4d4f53
  text-align: right
  font-weight: 400
  font-size: 16px
.cart-item-taste, .cart-item-quantity
  font-weight: 400
  color: #4D4F53
</style>
