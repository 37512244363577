<script>
import { computed, defineComponent, reactive, ref, watch } from 'vue'
import { useLayout } from 'src/composables/useLayout'
import { server } from 'boot/meteor'
import { useQuasar } from 'quasar'
import ProductListItem from 'components/product/ProductListItem'
import SearchSuggestion from 'components/layout/SearchSuggestion'
import { useRouter } from 'vue-router'
import { useAuth } from 'src/composables/useAuth'

export default defineComponent({
  name: 'SearchResultViewer',
  components: { SearchSuggestion, ProductListItem },
  props: ['visible'],
  setup (props, { emit }) {
    const { translate, thumbStyle, barStyle, locale, showSearchResults, showMobileSearchResults, showDrawer } = useLayout()
    const { showLogin } = useAuth()
    const router = useRouter()
    const $q = useQuasar()
    const searchSuggestions = ref(null)
    const searchCategories = ref(null)
    const loading = ref(false)
    const loadedOnce = ref(false)
    const items = ref([])
    const pagination = reactive({
      sortBy: 'nrOfPurchases',
      descending: true,
      productLimit: 6,
      mainProductLimit: 6,
      productRowsNumber: 0,
      mainProductRowsNumber: 0,
      rowsNumber: 0,
      productSkip: 0,
      mainProductSkip: 0
    })
    const query = reactive({
      searchType: ['PRODUCT'],
      searchText: null
    })

    const computedVisible = computed({
      get () {
        return props.visible
      },
      set (val) {
        emit('update:visible', val)
      }
    })
    watch(() => computedVisible.value, (val) => {
      if (!val) {
        searchInputStyle.value = false
      }
    })
    const searchInputStyle = ref(false)
    const changeInputStyle = computed(() => {
      if (searchInputStyle.value) {
        return '#0370f7'
      } return '#CACACA'
    })
    const openCategory = async (category) => {
      await router.push(`/termekek/${category}`)
      showLogin.value = false
      showSearchResults.value = false
      showDrawer.value = false
      showMobileSearchResults.value = false
    }

    const searchResults = async (val) => {
      loading.value = true
      try {
        const productsRes = await server.call('searchAllProducts', {
          ...query,
          ...pagination,
          iso: locale.value,
          searchRecommendation: !query.searchText ? true : undefined,
          productFields: ['_id', 'product_name', 'discount', 'prices', 'img', 'url', 'categories']
        })
        items.value = productsRes.items
        pagination.productRowsNumber = productsRes.productCount
        pagination.mainProductRowsNumber = productsRes.mainProductCount
        loading.value = false
        if (loadedOnce.value) {
          computedVisible.value = true
        } else {
          searchSuggestions.value = await server.call('getSearchSuggestions', {
            iso: locale.value,
            searchText: query.searchText
          })
          searchCategories.value = await server.call('getCategories', locale.value, query.searchText)
          loadedOnce.value = true
        }
      } catch (e) {
        console.log(e)
        loading.value = false
        computedVisible.value = false
        items.value = []
        $q.notify({ type: 'negative', message: e.reason })
      }
    }

    watch(query, async () => {
      await searchResults()
    }, { immediate: true, deep: true })

    const popupProxyStyle = computed(() => {
      if ($q.screen < 600 && $q.screen.gt.xs) {
        return 'border-radius: 15px !important; transform: translateY(25px); -webkit-transform: translateY(25px); -moz-transform: translateY(25px); -o-transform: translateY(25px);'
      } else if ($q.screen.lt.sm) {
        return ''
      } else {
        return 'margin-left: 1px !important; border-radius: 15px !important;'
      }
    })

    const searchHeight = computed(() => {
      if (items.value && items.value.length >= 4) {
        return 160 * 3
      } else if (items.value && items.value.length === 1) {
        return 120
      } else if (items.value && items.value.length === 2) {
        return 120 * 2
      } else if (items.value && items.value.length < 4) {
        return 160 * (items.value.length - 1)
      } else if (!items.value || items.value.length === 0) {
        return 0
      }
      return 0
    })

    const maxPages = computed(() => {
      return Math.round(
        (pagination.productRowsNumber + pagination.mainProductRowsNumber) /
        (pagination.productLimit + pagination.mainProductLimit)
      )
    })

    const current = computed({
      get () { return (pagination.productSkip / pagination.productLimit) + 1 },
      set (val) {
        pagination.productSkip = (val - 1) * pagination.productLimit
        pagination.mainProductSkip = (val - 1) * pagination.mainProductLimit
        searchResults()
      }
    })

    const loadMoreResults = async (index, done) => {
      try {
        if (items.value.length < (pagination.productSkip + pagination.mainProductSkip)) {
          const productsRes = await server.call('searchAllProducts', {
            joinGifts: false,
            ...query,
            ...pagination,
            iso: locale.value,
            productSkip: (index + 1) * pagination.productSkip,
            mainProductSkip: (index + 1) * pagination.mainProductSkip,
            productFields: ['_id', 'discount', 'product_name', 'prices', 'img', 'url', 'overallRating', 'ratingCount']
          })
          items.value = [...items.value, ...productsRes.items]
        }
        done()
      } catch (e) {
        done()
        console.log(e)
      }
    }

    return {
      searchInputStyle,
      changeInputStyle,
      searchResults,
      translate,
      searchSuggestions,
      searchCategories,
      loading,
      loadedOnce,
      thumbStyle,
      barStyle,
      query,
      pagination,
      popupProxyStyle,
      searchHeight,
      maxPages,
      current,
      openCategory,
      loadMoreResults,
      computedVisible,
      items
    }
  }
})
</script>

<template lang="pug">
.container(style="max-width: 440px")
  .search.q-mx-md.gt-sm
    q-input(ref="search-input" square dense :class="$q.screen.lt.sm ? 'full-width' : ''" outlined v-model="query.searchText" @input="searchResults()"
      :loading="loading && loadedOnce" style="width: 350px; position: relative; "
      :label="translate('home.searchText')" :debounce="400"
    ).full-width
      template(v-slot:append)
        svg(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24' fill="#999A9C")
          path(fill='none' d='M0 0h24v24H0z')
          path(d='M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15z')
        .cursor-pointer(@click="query.searchText = null; searchResults()" v-if="query.searchText")
          q-icon(name="la la-times" size="18px" color="white")
  q-popup-proxy(:breakpoint="600" fit :persistent="$q.screen.lt.sm" transition-show="scale" seamless :maximized="$q.screen.width <= 1024" :offset="$q.screen.gt.sm ? [-15, 15] : [0, 55]" v-model="computedVisible" transition-hide="scale" no-focus style="z-index: 9999;" :content-style="popupProxyStyle")
    .searchResultPopupContainer
      .lt-sm.search-wrapper
        q-input.search-input(@focus="searchInputStyle = true" @blur="searchInputStyle = false" :bg-color="searchInputStyle ? 'blue-grey-12' : ''" :rectangle="$q.screen.lt.md" outlined ref="search-input" :color="$q.screen.gt.md ? 'orange' : 'blue-grey-14'" v-model="query.searchText" @input="searchResults()"
          :loading="loading && loadedOnce" :placeholder="translate('home.searchText')" :dark="$q.screen.gt.md" :debounce="400" :label-color="$q.screen.gt.md ? 'orange' : 'grey-8'")
          template(v-slot:append)
            .cursor-pointer.q-pr-sm.flex.gt-sm(@click="query.searchText = null; searchResults()" v-if="query.searchText")
              q-icon(name="la la-times" size="18px" color="grey-7")
          template(v-slot:prepend)
            svg(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='28' height='28' style="transition: .33s all;" :fill="changeInputStyle")
              path(fill='none' d='M0 0h24v24H0z')
              path(d='M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15z')
        .close-btn(@click="computedVisible = false")
          svg(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='34' height='34' fill="white")
            path(fill='none' d='M0 0h24v24H0z')
            path(d='M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z')
      .lt-sm.mobileCategoriesTitleContainer {{translate(`${query.searchText ? 'home.searchResults' : 'home.searchSuggestions'}`)}}:
      div(:class="$q.screen.lt.sm ? '' : 'row'")
        .search-result-wrapper(:class="$q.screen.lt.sm ? 'col-12' : 'col-7 row'")
          q-scroll-area.full-width(ref="searchAreaRef" :visible="true" style="height: 100%; display: block !important;" :bar-style="barStyle" :thumb-style="thumbStyle")
            q-infinite-scroll.row.infinite(v-if="items && items.length > 0" style="height: 100%;" :disable="items.length >= (pagination.productSkip + pagination.mainProductSkip)" @load="loadMoreResults" :offset="100" debounce="300").full-width.justify-center
              template(v-slot:loading)
                .row.justify-center.q-my-md.flex.full-width
                  q-spinner-dots(color='grey' size='sm').full-width
              .full-width(v-for="(item, index) in items")
                product-list-item.full-width(:type="item.searchType" :key="item._id" :item="item" :wide="true" @clicked="computedVisible = false")
            .full-width.full-height.q-pa-lg.text-center.text-black(v-else) {{ translate('products.noItem') }}
        .lt-md.full-width
          .mobileCategoriesTitleContainer
            .label {{ translate('blog.category') }}
          .mobileCategoriesContainer
            q-scroll-area.searchCategoryScrollArea.lt-md.full-width(:thumb-style="{opacity: 0}" horizontal content-style="flex-direction: row; width: max-content !important; display: flex;" content-active-style="flex-direction: row; width: max-content !important; display: flex;")
              .categoryChip(v-if="searchCategories" v-for="searchCategory in searchCategories" :key="searchCategory._id" @click="openCategory(searchCategory.url); computedVisible = false;")
                router-link(:to="'/termekek/'+searchCategory.url").label {{ searchCategory.url }}
        .col-5.row.q-pa-lg.gt-sm(style="height: 100%; height: 400px; background: #F3F3F3;")
          .column.full-height
            .col-6
              .full-width
                div(:class="$q.screen.gt.sm ? 'q-mb-sm' : ''" style="font-size: 1.2em; padding-left: 5px; font-weight: 600; color: #313131") {{ translate('home.searchSuggestions') }}
                .full-width(:class="$q.screen.gt.sm ? '' : 'row'")
                  search-suggestion(v-if="searchSuggestions && searchSuggestions[0]" :suggestion="searchSuggestions[0]" v-model:search-text="query.searchText")
                  search-suggestion(v-if="searchSuggestions && searchSuggestions[1]" :suggestion="searchSuggestions[1]" v-model:search-text="query.searchText")
                  search-suggestion(v-if="searchSuggestions && searchSuggestions[2]" :suggestion="searchSuggestions[2]" v-model:search-text="query.searchText")
            .q-pt-md.col-6
              .full-width
                div(:class="$q.screen.gt.sm ? 'q-mb-sm' : ''" style="font-size: 1.2em; padding-left: 5px; font-weight: 600; color: #313131") {{ translate('home.recommendedCategories') }}
                .full-width
                  q-item.text-500.font-color.flex.items-center(clickable dense v-if="searchCategories && searchCategories[0]" :to="'/termekek/'+searchCategories[0].url" @click="openCategory(searchCategories[0].url); computedVisible = false;" :key="searchCategories[0]._id" style="padding-left: 5px !important; font-size: 0.9em;") {{ searchCategories[0].name }}
                  q-item.text-500.font-color.flex.items-center(clickable dense v-if="searchCategories && searchCategories[1]" :to="'/termekek/'+searchCategories[1].url" @click="openCategory(searchCategories[1].url); computedVisible = false;" :key="searchCategories[1]._id" style="padding-left: 5px !important; font-size: 0.9em;") {{ searchCategories[1].name }}
                  q-item.text-500.font-color.flex.items-center(clickable dense v-if="searchCategories && searchCategories[2]" :to="'/termekek/'+searchCategories[2].url" @click="openCategory(searchCategories[2].url); computedVisible = false;" :key="searchCategories[2]._id" style="padding-left: 5px !important; font-size: 0.9em;") {{ searchCategories[2].name }}
                .full-width(:class="$q.screen.gt.sm ? '' : 'row'").lt-md
                  q-chip.text-500.font-color.flex.items-center(clickable dense v-if="searchCategories && searchCategories[0]" :to="'/termekek/'+searchCategories[0].url" @click="openCategory(searchCategories[0].url); computedVisible = false;" :key="searchCategories[0]._id" style="padding-left: 5px !important; font-size: 0.9em;") {{ searchCategories[0].name }}
                  q-chip.text-500.font-color.flex.items-center(clickable dense v-if="searchCategories && searchCategories[1]" :to="'/termekek/'+searchCategories[1].url" @click="openCategory(searchCategories[1].url); computedVisible = false;" :key="searchCategories[1]._id" style="padding-left: 5px !important; font-size: 0.9em;") {{ searchCategories[1].name }}
                  q-chip.text-500.font-color.flex.items-center(clickable dense v-if="searchCategories && searchCategories[2]" :to="'/termekek/'+searchCategories[2].url" @click="openCategory(searchCategories[2].url); computedVisible = false;" :key="searchCategories[2]._id" style="padding-left: 5px !important; font-size: 0.9em;") {{ searchCategories[2].name }}
</template>

<style lang="sass">
.search
  .q-field--outlined .q-field__control:before
    border: 2px solid #dedede !important
.container
  .q-menu
    @media screen and (max-width: 1023px)
      max-width: 100vw !important
      max-height: calc(100vh - 55px) !important
    @media screen and (max-width: 1023px)
      width: 100vw !important
      height: 400px
.infinite > :last-child > * > hr
  display: none !important
.searchResultPopupContainer
  .q-field--outlined .q-field__control:hover:before
    border: none
  .q-field--outlined .q-field__control:before
    border: none
  .search-wrapper
    display: flex
    flex-direction: row
    align-items: center
    .search-input
      position: relative
      padding: 10px 6px 10px 10px
      flex: 1
      @media screen and (max-width: 1023px)
        font-size: 16px
        .q-field__marginal
          height: 100%
        .q-field__prepend
          padding-right: 10px !important
          height: 50px
        .q-field__control
          padding-left: 10px
          .q-field__label
            line-height: 14px
            color: #4D4F53 !important
        .q-field__inner
          height: 50px
          .q-field__control
            border-radius: 8px
            -webkit-box-shadow: inset 0 0 0 1px #CACACA
            box-shadow: inset 0 0 0 1px #CACACA
            height: 50px
          .q-field--dense
            height: 50px
    .close-btn
      display: flex
      cursor: pointer
      border-radius: 7px
      padding: 8px
      margin-right: 10px
      background: #4E4F53
  .search-result-title
    display: flex
    flex-direction: row
    padding: 0 16px
    width: 100%
    align-items: center
    height: 40px
    background: #ECECEE
    text-transform: uppercase
    font-weight: 600
    color: #ABABAD
    font-size: 14px
  height: 400px
  background: #FFFFFF
  width: 700px
  max-width: 100vw
  @media screen and (max-width: 1023px)
    width: 100vw !important
    height: 400px
  @media screen and (max-width: 599px)
    width: 100vw !important
    height: 100vh
  .search-result-wrapper
    @media screen and (max-width: 599px)
      height: calc(100vh - 213px)
.mobileCategoriesTitleContainer
  text-transform: uppercase
  font-weight: 500
  background: #ececee
  display: flex
  justify-content: center
  align-items: center
  width: 100%
  height: 40px
  color: #4D4F53
  font-size: 14px
  .label
    color: #4E4F53
    text-transform: uppercase
    font-size: 15px
    font-weight: 600
.mobileCategoriesContainer
  .searchCategoryScrollArea
    height: 100%
  height: 63px
  display: flex
  flex-direction: row
  .categoryChip
    align-self: center
    width: auto
    padding: 0 10px
    height: 40px
    background: #ececee
    border-radius: 100px
    display: flex
    align-items: center
    justify-content: center
    margin: 0 4px
    .label
      color: #4E4F53
      text-transform: uppercase
      font-size: 14px
      font-weight: 600
</style>
