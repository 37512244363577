<script>
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'S3Image',
  props: ['src', 'imgStyle'],
  setup (props) {
    const awsSrc = computed(() => {
      if (props.src) {
        return `https://assets.puregoldprotein.com/files/${props.src}`
      }
      return ''
    })
    return {
      awsSrc
    }
  }
})
</script>

<template lang="pug">
.flex.flex-center
  img(v-if="src" :src="awsSrc" :style="`width:100%; object-fit: cover; ${imgStyle};'`")
  q-img(src='~assets/img/missingImg.webp' v-else :style="imgStyle" ratio="1")
  slot
</template>

<style scoped>

</style>
