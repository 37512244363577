<script>
import { useLayout } from 'src/composables/useLayout'
import { useValidations } from 'src/composables/useValidations'
import { useQuasar } from 'quasar'
import { computed, ref } from 'vue'
import PromotionLine from 'components/layout/PromotionLine'
import S3Image from 'components/general/S3Image'
import { useFilters } from 'src/composables/useFilters'

export default {
  name: 'SelectGiftModal',
  components: { S3Image, PromotionLine },
  props: ['items', 'visible', 'maxGifts'],
  setup (props, { emit }) {
    const { translate, thumbStyle, barStyle } = useLayout()
    const { validatePassword } = useValidations()
    const $q = useQuasar()
    const { prettifyCurrency } = useFilters()

    const selectedProduct = ref(null)

    const computedItems = computed({
      get () {
        return props.items
      },
      set (val) {
        emit('update:items', val)
      }
    })
    const computedVisible = computed({
      get () {
        return props.visible
      },
      set (val) {
        emit('update:visible', val)
      }
    })

    const selectedGiftsCount = computed(() => {
      if (computedItems.value) {
        return computedItems.value.reduce((sum, current) => sum + (current.quantity || 0), 0)
      }
      return 0
    })
    const progressVal = computed(() => {
      return selectedGiftsCount.value / props.maxGifts
    })
    function openProductInfo (product) {
      emit('open', product)
    }
    function increment (gift) {
      if (selectedGiftsCount.value >= props.maxGifts) {
        $q.notify({ type: 'info', message: translate('product.maxGiftError') })
        return
      }
      gift.quantity++
      emit('updated', props.items.filter(e => e.quantity > 0))
    }
    function decrement (gift) {
      if (gift.quantity <= 0) {
        return
      }
      gift.quantity--
      emit('updated', props.items.filter(e => e.quantity > 0))
    }
    function selectGradientStyle (quantity) {
      if ($q.screen.gt.xs) {
        if (quantity > 0) {
          return 'border-radius: 8px; -moz-box-shadow: inset 0px 0px 0px 2px #0370F7, 0px 2px 2px 0px #dfe0e3; -o-box-shadow: inset 0px 0px 0px 2px #0370F7, 0px 2px 2px 0px #dfe0e3; -webkit-box-shadow: inset 0px 0px 0px 2px #0370F7, 0px 2px 2px 0px #dfe0e3; box-shadow: inset 0px 0px 0px 2px #0370F7, 0px 2px 2px 0px #dfe0e3; background: #E0E6FA;'
        } else {
          return 'border-radius: 8px; -moz-box-shadow: inset 0px 0px 0px 1px #E0E0E0, 0px 2px 2px 0px #dfe0e3; -o-box-shadow: inset 0px 0px 0px 1px #E0E0E0, 0px 2px 2px 0px #dfe0e3; -webkit-box-shadow: inset 0px 0px 0px 1px #E0E0E0, 0px 2px 2px 0px #dfe0e3; box-shadow: inset 0px 0px 0px 1px #E0E0E0, 0px 2px 2px 0px #dfe0e3;'
        }
      }
    }
    function selectGradientClass (quantity, index) {
      if ($q.screen.lt.sm) {
        if (index > 0) {
          if (quantity > 0) {
            return 'gift-selected-bg q-py-sm'
          } else {
            return 'q-py-sm'
          }
        } else {
          if (quantity > 0) {
            return 'gift-selected-bg q-pb-sm'
          } else {
            return 'q-pb-sm'
          }
        }
      } else {
        return 'q-py-xs q-mb-md'
      }
    }

    return {
      translate,
      validatePassword,
      thumbStyle,
      barStyle,
      selectedProduct,
      selectGradientClass,
      openProductInfo,
      increment,
      decrement,
      selectGradientStyle,
      progressVal,
      computedVisible,
      selectedGiftsCount,
      prettifyCurrency
    }
  }
}
</script>

<template lang="pug">
q-dialog(v-model='computedVisible' :seamless="$q.screen.lt.sm")
  q-card(:class="$q.screen.lt.sm ? 'no-shadow full-width' : ''" :style="$q.screen.lt.sm ? `position: absolute !important; top: 55px; height: 100vh; max-height: 100%; border-radius: 0;` : 'border-radius: 15px; width: 710px; max-width: 710px !important; height: 75vh;'")
    promotion-line.lt-sm
    .selectItemModal.full-width.gift-select-dialog
      .select-gift-wrapper
        .title-wrapper
          svg.select-gift-icon(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' :width="$q.screen.gt.sm ? '34' : '26'" :height="$q.screen.gt.sm ? '32' : '26'")
            path(fill='none' d='M0 0h24v24H0z')
            path(fill="#0370F7" d='M15 2a4 4 0 0 1 3.464 6.001L23 8v2h-2v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V10H1V8l4.536.001A4 4 0 0 1 12 3.355 3.983 3.983 0 0 1 15 2zm-4 8H5v9h6v-9zm8 0h-6v9h6v-9zM9 4a2 2 0 0 0-.15 3.995L9 8h2V6a2 2 0 0 0-1.697-1.977l-.154-.018L9 4zm6 0a2 2 0 0 0-1.995 1.85L13 6v2h2a2 2 0 0 0 1.995-1.85L17 6a2 2 0 0 0-2-2z')
          .text-center.dark-color.gift-select-title.flex.items-center(style="font-weight: 600") {{ translate("product.selectGift") }}!
        .gift-chip {{selectedGiftsCount}}/{{maxGifts}}
        .absolute-right.row.items-center
          .cursor-pointer.flex.flex-center(@click="computedVisible = false" :style="`padding-right: ${$q.screen.lt.sm ? '10px' : '20px'}`")
            svg(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' :width="$q.screen.gt.sm ? '32' : '28'" :height="$q.screen.gt.sm ? '32' : '28'" fill="#242424")
              path(fill='none' d='M0 0h24v24H0z')
              path(d='M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z')
      q-linear-progress.linear-progress(:value="progressVal" :size="$q.screen.gt.sm ? '7px' : '5px'")
      div(:class="$q.screen.gt.xs ? 'q-pt-md' : 'q-pt-sm'")
        q-scroll-area.select-gift-scrollarea(:visible="true" :style="$q.screen.lt.sm ? `height: calc(100vh - ${progressVal > 0 ? '258px' : '181px'})` : `height: calc(75vh - 125px);`" :thumb-style="thumbStyle" :bar-style="barStyle")
          .row.flex.items-center(v-for="(item, index) in items" :key="item.product._id")
            .row.full-width.no-wrap(:style="selectGradientStyle(item.quantity)" :class="selectGradientClass(item.quantity, index)" style="max-width: 100vw")
              .row.no-wrap(style="flex: 1")
                .row.items-center.gt-xs.cursor-pointer(@click="openProductInfo(item.product)" :class="$q.screen.lt.sm ? '' : 'q-pl-lg'")
                  svg(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='30' height='30')
                    path(fill='none' d='M0 0h24v24H0z')
                    path(d='M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM11 7h2v2h-2V7zm0 4h2v6h-2v-6z' fill='rgba(204,204,204,1)')
                  q-tooltip(v-if="$q.screen.lt.md") {{ translate('products.openItemPopup') }}
                .row.items-center.q-py-sm.no-wrap
                  s3-image(:ratio="1" :src="item.product.img[0]" :img-style="`width: ${$q.screen.gt.sm ? 110 : 90}px;`" :style="`width: ${$q.screen.gt.sm ? 110 : 90}px;`")
                  .q-pr-sm
                    div(:class="$q.screen.lt.sm ? 'q-pb-sm' : ''" )
                      .q-pb-xs.giftName {{ item.product.product_name }}
                        q-tooltip {{ item.product.product_name }}
                      .dark-color(:style="`font-size: 15px;`" v-if="item.product && item.product.categories && item.product.categories[0]") {{ item.product.categories[0].name }}
                    .row.flex.items-center.lt-sm
                      .cursor-pointer(@click="decrement(item)" :style="`-webkit-box-shadow: 0px 2px 2px 0px #dfe0e3; box-shadow: 0px 2px 2px 0px #dfe0e3; border: 1px solid ${item.quantity > 0 ? '#2D5FD0' : '#ECECEE'}; background: ${item.quantity > 0 ? '#0370F7' : '#ECECEE'}; border-radius: 8px; width: 35px; height: 35px;`").flex.flex-center
                        svg(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='26' height='26' :fill="item.quantity > 0 ? 'white' : '#A3A3A3'")
                          path(fill='none' d='M0 0h24v24H0z')
                          path(d='M5 11h14v2H5z')
                      .flex.flex-center.text-600.gift-quantity(:style="`padding: 0 ${$q.screen.lt.sm ? '0px' : '32px'}; color: ${item.quantity > 0 ? '#353535' : '#ACACAC'};`") {{ item.quantity }}
                      .cursor-pointer(@click="increment(item)" :style="`-webkit-box-shadow: 0px 2px 2px 0px #dfe0e3; box-shadow: 0px 2px 2px 0px #dfe0e3; border: 1px solid ${item.quantity > 0 ? '#2D5FD0' : '#ECECEE'}; background: ${item.quantity > 0 ? '#0370F7' : '#ECECEE'}; border-radius: 8px; width: 35px; height: 35px;`").flex.flex-center
                        svg(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='26' height='26' :fill="item.quantity > 0 ? 'white' : '#242426'")
                          path(fill='none' d='M0 0h24v24H0z')
                          path(d='M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z')
              .row.gt-xs.justify-between.items-center.no-wrap(style="max-width: 280px;")
                .q-pr-md.text-right(style="font-size: 20px; line-height: 24px;")
                  .text-500.dark-color.text-strike.text-right.text-no-wrap {{ prettifyCurrency(item.product.prices.gross_price, 0) }}
                  .text-500.red-color.text-no-wrap {{ prettifyCurrency(item.prices.gross_price, 0) }}
                .row.items-center.q-pr-lg.no-wrap
                  .cursor-pointer(@click="decrement(item)" :style="`-webkit-box-shadow: 0px 2px 2px 0px #dfe0e3; box-shadow: 0px 2px 2px 0px #dfe0e3; border: 1px solid ${item.quantity > 0 ? '#2D5FD0' : '#ECECEE'}; background: ${item.quantity > 0 ? '#0370F7' : '#FFFFFF'}; border-radius: 8px; width: 45px; height: 45px;`").flex.flex-center
                    svg(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='26' height='26' :fill="item.quantity > 0 ? 'white' : '#A3A3A3'")
                      path(fill='none' d='M0 0h24v24H0z')
                      path(d='M5 11h14v2H5z')
                  .q-px-md.flex.flex-center.text-600.gift-quantity(:style="`color: ${item.quantity > 0 ? '#353535' : '#ACACAC'};`") {{ item.quantity }}
                  .cursor-pointer(@click="increment(item)" :style="`-webkit-box-shadow: 0px 2px 2px 0px #dfe0e3; box-shadow: 0px 2px 2px 0px #dfe0e3; border: 1px solid ${item.quantity > 0 ? '#2D5FD0' : '#ECECEE'}; background: ${item.quantity > 0 ? '#0370F7' : '#FFFFFF'}; border-radius: 8px; width: 45px; height: 45px;`").flex.flex-center
                    svg(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='26' height='26' :fill="item.quantity > 0 ? 'white' : 'black'")
                      path(fill='none' d='M0 0h24v24H0z')
                      path(d='M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z')
              .column.items-end.q-mr-sm.q-pb-sm.lt-sm.items-center(style="width: 100px;")
                .row.items-center.cursor-pointer(@click="openProductInfo(item.product)" style="flex: 1")
                  svg(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='30' height='30' fill="#C3C3C3")
                    path(fill='none' d='M0 0h24v24H0z')
                    path(d='M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM11 7h2v2h-2V7zm0 4h2v6h-2v-6z')
                    q-tooltip(v-if="$q.screen.lt.md") {{ translate('products.openItemPopup') }}
                .q-pr-md.text-right(style="font-size: 20px; line-height: 24px;")
                  .text-500.dark-color.text-strike.text-right.text-no-wrap {{ prettifyCurrency(item.product.prices.gross_price, 0) }}
                  .text-500.red-color.text-no-wrap {{ prettifyCurrency(item.prices.gross_price, 0) }}
            q-separator.full-width.lt-sm(style="height: 1px;" v-if="index < items.length - 1")
          .row.full-width.justify-center.fixed-bottom.lt-sm(style="height: 85px;" v-if="progressVal > 0")
            q-btn.to-basket.square.text-white.text-600(:label="translate('products.toBasket')" @click="computedVisible = false; $emit('addToCart')")
</template>

<style lang="sass" scoped>
.gift-select-dialog
  .select-gift-scrollarea
    width: 100%
    padding-left: 24px
    padding-right: 24px
    @media screen and (max-width: 600px)
      padding: 0
  .select-gift-icon
    margin-right: 16px
    @media screen and (max-width: 600px)
      margin-left: 16px !important
      margin-right: 12px !important
  .select-gift-wrapper
    display: flex
    width: 100%
    flex-direction: row
    height: 85px
    padding-left: 40px
    align-items: center
    position: relative
    @media screen and (max-width: 600px)
      padding: 16px 20px 16px 0 !important
      height: 65px !important
  .title-wrapper
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    @media screen and (max-width: 600px)
      justify-content: left !important
  .gift-select-title
    font-size: 24px
    @media screen and (max-width: 600px)
      font-size: 18px !important
      font-weight: 500 !important
    @media screen and (max-width: 350px)
      font-size: 16px !important
  .to-basket
    width: 100%
    font-size: 20px
    background: rgb(16,108,235)
    background: -moz-linear-gradient(90deg, rgba(16,108,235,1) 0%, rgba(241,34,42,1) 100%)
    background: -webkit-linear-gradient(90deg, rgba(16,108,235,1) 0%, rgba(241,34,42,1) 100%)
    background: linear-gradient(90deg, rgba(16,108,235,1) 0%, rgba(241,34,42,1) 100%)
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#106ceb",endColorstr="#f1222a",GradientType=1)
.scroll
  align-items: start
.selectModal
  background: #FFFFFF
  width: 600px
  @media screen and (max-width: 600px)
    width: initial
.gift-selected-bg
  background: #E7EFFD
.gift-chip
  align-self: center
  color: white
  margin: 0 16px
  padding: 2px 14px !important
  background: #F0232B
  font-weight: 600
  font-size: 17px
  border-radius: 30px !important
.linear-progress
  width: 100%
  .q-linear-progress__track
    background: #EBEBED
  .q-linear-progress__model--determinate
    background: linear-gradient(90deg, #0370F7 0, #F0232B 100%) !important
.gift-quantity
  min-width: 60px
  font-size: 22px
  @media screen and (max-width: 600px)
    min-width: 50px !important
    font-size: 18px
  @media screen and (max-width: 355px)
    min-width: 30px !important
    font-size: 18px
.giftName
  font-weight: 600
  color: #4D4F53
  font-size: 18px
  line-height: 20px
  width: 100%
  overflow: hidden
  max-width: 100vw !important
  @media screen and (max-width: 1024px)
    font-weight: 500
    max-width: calc(100vw - 200px) !important
    font-size: 16px
</style>
