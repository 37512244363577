<script>
import { useRoute, useRouter } from 'vue-router'
import { computed } from 'vue'
export default {
  name: 'MobileMenuItem',
  props: ['label', 'path', 'isActive'],
  setup (props, { emit }) {
    const router = useRouter()
    const route = useRoute()
    const selectItemStyle = computed(() => {
      if (props.path === route.path || props.isActive) {
        return 'color: #0370F7 !important;'
      } return 'color: #48494E !important;'
    })
    function clicked () {
      if (props.path) {
        if (props.path === route.path) {
          emit('close')
        } else {
          router.push(props.path)
        }
      } else {
        emit('clicked')
      }
    }
    return {
      selectItemStyle,
      clicked
    }
  }
}
</script>

<template lang="pug">
div
  .listItem(@click="clicked")
    .label(:style="selectItemStyle") {{label}}
    svg(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24' v-if="!path")
      path(fill='none' d='M0 0h24v24H0z')
      path(d='M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z')
  q-separator(size="1px" style="background-color: #ECECEC; margin: 0 10px")
</template>

<style scoped lang="sass">
.listItem
  cursor: pointer
  display: flex
  flex-direction: row
  align-items: center
  justify-content: space-between
  padding: 10px 15px 10px 35px
  .label
    font-size: 16px
    font-weight: 500
</style>
