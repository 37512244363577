import { reactive, toRefs, computed } from 'vue'
import { server } from 'boot/meteor'

const state = reactive({
  showDrawer: false,
  showSearchResults: false,
  showMobileSearchResults: false,
  productFamilies: [],
  similarProducts: [],
  randomizedDiscount: Math.floor(Math.random() * 2),
  thumbStyle: reactive({
    right: '0px',
    borderRadius: '8px',
    backgroundColor: '#2D5ED9',
    width: '8px',
    opacity: 0.75
  }),
  barStyle: reactive({
    backgroundColor: '#CDE2FD',
    width: '8px',
    opacity: 1
  }),
  categories: [],
  extraMenus: [],
  featuredProducts: [],
  country: null,
  countries: null,
  language: null,
  locale: 'hu',
  FOOTER_1: [],
  appLoaded: false
})

export function useLayout () {
  const translate = (path) => {
    try {
      let res = state.language
      path.split('.').forEach(e => { res = res[e] })
      return res
    } catch (e) {
      // console.log('TRANS E', e)
      return path
    }
  }

  const startAppFirstPaint = async () => {
    try {
      if (!state.language || !state.country || !state.countries || !state.appLoaded) {
        const lang = await server.call('loadLangFile', state.locale)
        state.language = lang.translations
        state.country = lang.country
        state.countries = lang.countries
        state.appLoaded = true
      }
    } catch (e) {
      console.log('APP START FAILED', e)
    }
  }

  const startAppRest = async () => {
    try {
      if (!state.productFamilies || state.productFamilies.length === 0) {
        state.productFamilies = await server.call('getProductFamilies', state.locale)
      }
      if (!state.categories || state.categories.length === 0) {
        state.categories = await server.call('getCategories', state.locale)
      }
      if (!state.featuredProducts || state.featuredProducts.length === 0) {
        const featuredProducts = await server.call('searchAllProducts', {
          iso: state.locale,
          sortBy: 'nrOfPurchases',
          descending: true,
          productLimit: 4,
          mainProductLimit: 4,
          mainProductSkip: 0,
          productSkip: 0,
          featuredProduct: true,
          productFields: ['_id', 'product_name', 'prices', 'discount', 'img', 'tasteImg', 'tasteOrder', 'packagingOrder', 'packaging', 'unitPrice', 'taste', 'stock', 'url', 'product_code', 'wishListAdded', 'overallRating', 'ratingCount', 'gifts', 'nrOfAllowedGifts']
        })
        if (featuredProducts && featuredProducts.items) {
          state.featuredProducts = featuredProducts.items
        }
      }
      if ((!state.extraMenus || state.extraMenus.length === 0) || (!state.FOOTER_1 || state.FOOTER_1.length === 0)) {
        const staticPages = await server.call('searchStaticPages', { iso: state.locale })
        if (staticPages.items && staticPages.items.length > 0) {
          const extraMenus = []
          const FOOTER_1 = []
          staticPages.items.forEach(staticPage => {
            if (staticPage.showInMenu) {
              extraMenus.push(staticPage)
            }
            if (staticPage.category === 'FOOTER_1') {
              FOOTER_1.push(staticPage)
            }
          })
          state.extraMenus = extraMenus
          state.FOOTER_1 = FOOTER_1
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  const headerHeight = computed(() => {
    if (window.innerWidth > 1040) {
      return 199
    }
    return 100
  })

  return {
    ...toRefs(state),
    startAppRest,
    startAppFirstPaint,
    translate,
    headerHeight
  }
}
