<script>
import { useLayout } from 'src/composables/useLayout'
import { useValidations } from 'src/composables/useValidations'
import { onBeforeUnmount, reactive, ref, watch } from 'vue'
import { server } from 'boot/meteor'
import { useQuasar } from 'quasar'
import { useAuth } from 'src/composables/useAuth'

export default {
  name: 'AuthModal',
  setup () {
    const { translate, thumbStyle, barStyle } = useLayout()
    const { showLogin, authUserWithEmailAndPassword, showNoPasswordSet, authTab } = useAuth()
    const $q = useQuasar()
    const { required, validateEmail, validatePassword } = useValidations()

    const email = ref(null)
    const password = ref(null)
    const newUser = reactive({
      email: 'barnabastoth94@gmail.com',
      fullName: 'asdasd',
      password: 'asdasd',
      passwordAgain: 'asdasd'
    })

    onBeforeUnmount(() => {
      authTab.value = 'login'
    })

    watch(showNoPasswordSet, val => {
      if (val) {
        authTab.value = 'missingPw'
      }
    })

    async function resetPassword () {
      try {
        await server.call('sendPasswordResetEmail', email.value)
        showLogin.value = false
        authTab.value = 'login'
        $q.notify({ type: 'positive', message: translate('auth.pwResetEmailSent') })
      } catch (e) {
        $q.notify({ type: 'negative', message: e.reason })
      }
    }
    async function register () {
      if (newUser.password !== newUser.passwordAgain) {
        $q.notify({ type: 'info', message: translate('auth.pwNotMatch') })
        return
      }
      try {
        await server.call('register', newUser)
        authTab.value = 'login'
        email.value = newUser.email
        password.value = newUser.password
        newUser.email = null
        newUser.password = null
        newUser.passwordAgain = null
        newUser.fullName = null
        $q.notify({ type: 'positive', message: translate('auth.registeredSuccess') })
        showLogin.value = false
      } catch (e) {
        console.log(e)
        if (e.reason === 'Email already exists.') {
          $q.notify({ type: 'negative', message: translate('error.Email already exists') })
        } else {
          $q.notify({ type: 'negative', message: e.reason })
        }
      }
    }
    function loginWithFacebook () {
      this.$Meteor.loginWithFacebook({ requestPermissions: ['email'] }, (err) => {
        if (err) { $q.notify({ type: 'negative', message: translate('error.' + err.reason) }); return }
        redirectAfterLogin()
      })
    }
    function redirectAfterLogin () {
      $q.notify({ type: 'positive', message: translate('auth.successfulLogin') })
      showLogin.value = false
    }

    const login = async () => {
      console.log('LISTENER SET')
      window.onbeforeunload = async function () {
        await server.call('windowClosed')
      }
      await authUserWithEmailAndPassword({ email: email.value, password: password.value })
    }

    const innerHeight = window.innerHeight

    return {
      innerHeight,
      translate,
      required,
      validateEmail,
      validatePassword,
      thumbStyle,
      barStyle,
      resetPassword,
      register,
      login,
      loginWithFacebook,
      email,
      password,
      authTab,
      newUser,
      showLogin,
      showNoPasswordSet
    }
  }
}
</script>

<template lang="pug">
q-dialog.authModal(v-model='showLogin' :maximized="$q.screen.lt.md" :seamless="$q.screen.lt.sm")
  q-card.auth-modal-card.full-height
    .absolute-top-right.row.items-center(style="z-index: 1;")
      .cursor-pointer(@click="showLogin = false" style="padding: 10px 10px 0 0")
        svg(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='30' height='30' fill="#4D4F53")
          path(fill='none' d='M0 0h24v24H0z')
          path(d='M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z')
    .row.no-wrap.full-width.full-height
      img(src="~assets/img/login-img.webp" style="object-fit: contain; max-height: 700px").gt-sm
      .full-width.flex-1
        q-scroll-area.full-width.full-height(:bar-style="barStyle" :thumb-style="thumbStyle")
          q-tab-panels.transparent.full-width(v-model='authTab' animated)
            q-tab-panel.q-pa-none(name='missingPw' :style="`padding: ${$q.screen.gt.sm ? '36px 36px 0 36px' : '0'};`")
              .column.justify-between.q-px-md
                .q-my-md.full-width.text-center.q-pb-sm.text-600.dark-color(style="font-size: 28px;font-weight:600;") Új jelszó beállítása
                .q-my-md.full-width.text-center.q-mb-lg(style="font-size:18px;") Oldalunk megújult! Sajnos szükséges, hogy minden régi felhasználónk új jelszót állítson be magának. Hogy ezt meg tudd tenni, kérlek kattints a Jelszó emlékeztető küldése gombra, és nyisd meg a linket amit küldtünk emailben.
                q-btn.q-my-md.login-btn.flex.flex-center.full-width(@click="resetPassword" :label="translate('auth.resetPassword')" clickable :style="`padding: 10px !important; border-radius: ${$q.screen.gt.sm ? 8 : 0}px; height: ${$q.screen.lt.md ? 70 + 'px' : 'auto'};`")
            q-tab-panel.q-pa-none(name='login' :style="`padding: ${$q.screen.gt.sm ? '36px 36px 0 36px' : '0'};`")
              q-form(@submit='login')
                .full-width.flex.flex-center.q-pa-sm.q-mt-sm
                  svg(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='70' height='70' fill="#F0232B")
                    path(fill='none' d='M0 0h24v24H0z')
                    path(d='M12 17c3.662 0 6.865 1.575 8.607 3.925l-1.842.871C17.347 20.116 14.847 19 12 19c-2.847 0-5.347 1.116-6.765 2.796l-1.841-.872C5.136 18.574 8.338 17 12 17zm0-15a5 5 0 0 1 5 5v3a5 5 0 0 1-4.783 4.995L12 15a5 5 0 0 1-5-5V7a5 5 0 0 1 4.783-4.995L12 2zm0 2a3 3 0 0 0-2.995 2.824L9 7v3a3 3 0 0 0 5.995.176L15 10V7a3 3 0 0 0-3-3z')
                .gt-sm.full-width.text-center.q-pb-sm.text-600.dark-color(style="font-size: 28px;font-weight:600;") {{ translate('auth.loginLong') }}
                .lt-md.full-width.text-center.q-pb-sm.text-600.dark-color(style="font-size: 32px;font-weight:600;") {{ translate('auth.loginLong') }}
                .full-width.text-center.q-mb-lg(style="font-size:18px;") {{translate('auth.noAccount')}}
                  a.text-500(@click="authTab = 'register'" style="cursor:pointer; color: rgb(16,108,235);") {{ " "+translate('auth.register')}}
                .row(style="padding-bottom: 15px;").q-px-md
                  q-input.full-width.login-input(label-color="#4D4F53" hide-bottom-space outlined :label="translate('auth.emailPlaceholder')" lazy-rules='lazy-rules' v-model='email' :rules='[validateEmail]')
                .row.q-mb-xs.q-px-md
                  q-input.full-width.login-input(label-color="#4D4F53" hide-bottom-space outlined :label="translate('auth.passwordPlaceholder')" lazy-rules='lazy-rules' type='password' v-model='password' :rules='[validatePassword]' )
                .column
                  .forgotten-password.dark-color.cursor-pointer.text-center.q-pt-md.q-pb-md(@click="authTab = 'resetPassword'") {{translate('auth.forgottenPassword')}}
                  q-btn.q-mb-md.login-btn.flex.flex-center(clickable :style="`border-radius: ${$q.screen.gt.sm ? 8 : 0}px; height: ${$q.screen.lt.md ? 70 + 'px' : 'auto'};`" type='submit')
                    | {{translate('auth.login')}}
                  .row.full-width.items-center
                    q-separator.dark-color.col
                    .text-center.dark-color.q-px-sm(style="font-size: 17px;") {{translate('auth.fastRegister')}}
                    q-separator.dark-color.col
                  q-item.facebook-btn.flex.flex-center.q-my-md(clickable :style="`border-radius: ${$q.screen.gt.sm ? 8 : 0}px; height: ${$q.screen.lt.md ? 70 + 'px' : 'auto'};`" @click='loginWithFacebook')
                    .row.full-width.justify-center.relative-position
                      .row.items-center.absolute-left
                        svg(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24')
                          path(fill='none' d='M0 0h24v24H0z')
                          path(d='M14 13.5h2.5l1-4H14v-2c0-1.03 0-2 2-2h1.5V2.14c-.326-.043-1.557-.14-2.857-.14C11.928 2 10 3.657 10 6.7v2.8H7v4h3V22h4v-8.5z' fill='rgba(255,255,255,1)')
                      .text-white {{ translate('auth.continueWithFacebook') }}
                      div
            q-tab-panel.q-pa-none(name='register' :style="`padding: ${$q.screen.gt.sm ? '36px 36px 0 36px' : '0'};`")
              q-form(@submit='register')
                .full-width.flex.flex-center.q-pa-sm.q-mt-sm
                  svg(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='70' height='70' fill="#F0232B")
                    path(fill='none' d='M0 0h24v24H0z')
                    path(d='M12 17c3.662 0 6.865 1.575 8.607 3.925l-1.842.871C17.347 20.116 14.847 19 12 19c-2.847 0-5.347 1.116-6.765 2.796l-1.841-.872C5.136 18.574 8.338 17 12 17zm0-15a5 5 0 0 1 5 5v3a5 5 0 0 1-4.783 4.995L12 15a5 5 0 0 1-5-5V7a5 5 0 0 1 4.783-4.995L12 2zm0 2a3 3 0 0 0-2.995 2.824L9 7v3a3 3 0 0 0 5.995.176L15 10V7a3 3 0 0 0-3-3z')
                .gt-sm.full-width.text-center.q-pb-sm.text-600.dark-color(style="font-size: 28px;font-weight:600;") {{ translate('auth.register') }}
                .lt-md.full-width.text-center.q-pb-sm.text-600.dark-color(style="font-size: 32px;font-weight:600;") {{ translate('auth.register') }}
                .full-width.text-center.q-mb-lg(style="font-size:18px;") {{translate('auth.gotAccount')}}
                  a.text-500(@click="authTab = 'login'" style="cursor:pointer; color: rgb(16,108,235);") {{ " "+translate('auth.login')}}
                .row(style="padding-bottom: 15px;").q-px-md
                  q-input.full-width.login-input(label-color="#4D4F53" hide-bottom-space outlined :label="translate('auth.fullName')" lazy-rules='lazy-rules' v-model='newUser.fullName' :rules='[required]')
                .row(style="padding-bottom: 15px;").q-px-md
                  q-input.full-width.login-input(label-color="#4D4F53" hide-bottom-space outlined lazy-rules='lazy-rules' v-model='newUser.email' :rules='[validateEmail]' :label="translate('auth.email')" )
                .row(style="padding-bottom: 15px;").q-px-md
                  q-input.full-width.login-input(label-color="#4D4F53" hide-bottom-space outlined type='password' v-model='newUser.password' :rules='[validatePassword]' :label="translate('auth.password')" )
                .row(style="padding-bottom: 15px;").q-px-md
                  q-input.full-width.login-input(label-color="#4D4F53" hide-bottom-space outlined lazy-rules='lazy-rules' type='password' v-model='newUser.passwordAgain' :rules='[validatePassword]' :label="translate('auth.passwordAgain')" )
                .row(style="padding-bottom: 15px;").q-pt-md
                  q-btn.q-mb-md.login-btn.flex.flex-center.full-width(clickable :style="`border-radius: ${$q.screen.gt.sm ? 8 : 0}px; height: ${$q.screen.lt.md ? 70 + 'px' : 'auto'};`" type='submit')
                    | {{translate('auth.register')}}
                  .row.full-width.items-center
                    q-separator.dark-color.col
                    .text-center.dark-color.q-px-sm(style="font-size: 17px;") {{translate('auth.fastRegister')}}
                    q-separator.dark-color.col
                  q-item.facebook-btn.flex.flex-center.q-my-md.full-width(clickable :style="`border-radius: ${$q.screen.gt.sm ? 8 : 0}px; height: ${$q.screen.lt.md ? 70 + 'px' : 'auto'};`" @click='loginWithFacebook')
                    .row.full-width.justify-center.relative-position
                      .row.items-center.absolute-left
                        svg(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24')
                          path(fill='none' d='M0 0h24v24H0z')
                          path(d='M14 13.5h2.5l1-4H14v-2c0-1.03 0-2 2-2h1.5V2.14c-.326-.043-1.557-.14-2.857-.14C11.928 2 10 3.657 10 6.7v2.8H7v4h3V22h4v-8.5z' fill='rgba(255,255,255,1)')
                      .text-white {{ translate('auth.continueWithFacebook') }}
                      div
            q-tab-panel.loginForm(name='resetPassword' :style="`padding: ${$q.screen.gt.sm ? '36px 36px 0 36px' : '0'};`")
              q-form(@submit="resetPassword").q-mt-md
                .full-width.flex.flex-center.q-pa-sm.q-mt-sm
                  svg(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='70' height='70' fill="#F0232B")
                    path(fill='none' d='M0 0h24v24H0z')
                    path(d='M7 10h13a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V11a1 1 0 0 1 1-1h1V9a7 7 0 0 1 13.262-3.131l-1.789.894A5 5 0 0 0 7 9v1zm3 5v2h4v-2h-4z')
                .gt-sm.full-width.text-center.q-pb-sm.text-600.dark-color(style="font-size: 28px;font-weight:600;") {{ translate('auth.enterEmailReset') }}
                .lt-md.full-width.text-center.q-pb-sm.text-600.dark-color(style="font-size: 32px;font-weight:600;") {{ translate('auth.enterEmailReset') }}
                .full-width.text-center.q-mb-lg(style="font-size:18px;") {{translate('auth.enterEmailForReset')}}
                .q-mb-lg.text-center.text-black
                  q-input.q-mb-lg.q-px-md.full-width.q-mt-md(hide-bottom-space  filled lazy-rules='lazy-rules' v-model='email' :rules='[required, validateEmail]' :label="translate('auth.email')" )
                  q-btn.q-mb-md.login-btn.flex.flex-center.full-width(:label="translate('auth.resetPassword')" clickable :style="`padding: 10px !important; border-radius: ${$q.screen.gt.sm ? 8 : 0}px; height: ${$q.screen.lt.md ? 70 + 'px' : 'auto'};`" type='submit')
</template>

<style lang="sass">
.auth-modal-card
  position: relative
  border-radius: 15px
  width: 800px
  max-width: 800px !important
  @media screen and (max-width: 1023px)
    padding-bottom: 50px
    box-shadow: none !important
    margin-top: 58px !important
    width: 100vw !important
    max-width: 100vw !important
.authModal
  .q-dialog__inner--minimized > div
    max-height: 700px
    max-width: 750px !important
    overflow: hidden
  .q-field--filled .q-field__control
    border-radius: 5px !important
    border: 1px solid #D2D2D2
.login-btn, .facebook-btn
  font-weight: 600
  font-size: 17px
  padding: 22px 20px
  text-transform: uppercase
.login-btn
  .q-btn__wrapper
    padding: 0 !important
  color: white
  background: linear-gradient(90deg, rgba(16,108,235,1) 0%, rgba(241,34,42,1) 100%)
.facebook-btn
  background: #3B5998
.forgotten-password
  width: 100%
  background: transparent
  font-size: 17px
  font-weight: 400
.login-input
  font-size: 17px
  .q-field__control
    height: 60px
    border-radius: 8px
  .q-field__control:before
    border: 1px solid transparent
    box-shadow: 0 2px 2px 0 rgba(223,224,227,1)
    -webkit-box-shadow: 0 2px 2px 0 rgba(223,224,227,1)
    -moz-box-shadow: 0 2px 2px 0 rgba(223,224,227,1)
.login-input > * > .q-field__control
  background: #F2F2F2
  border: none
</style>
