import { reactive, toRefs } from 'vue'
import { server } from 'boot/meteor'
import { useLayout } from 'src/composables/useLayout'

const { locale } = useLayout()

const state = reactive({
  products: [],
  loading: false,
  loaded: false,
  tastes: [],
  packagings: [],
  category: null,
  productFamily: null,
  diets: [],
  query: {
    diet: [],
    taste: [],
    packaging: [],
    category: [],
    inStorage: null,
    iso: 'hu',
    categoryUrl: null,
    productFamilyUrl: null
  },
  pagination: {
    sortBy: 'nrOfPurchases',
    descending: true,
    productLimit: 8,
    mainProductLimit: 8,
    productRowsNumber: 0,
    mainProductRowsNumber: 0,
    productSkip: 0,
    mainProductSkip: 0
  }
})

export function useProductsPage () {
  const loadProductsPage = async (url) => {
    try {
      const configRes = await server.call('loadProductsConfiguration', { iso: locale.value, url })
      state.tastes = configRes.tastes
      state.packagings = configRes.packagings
      state.diets = configRes.diets
      state.category = configRes.category
      state.productFamily = configRes.productFamily
      state.pagination.productLimit = 8
      state.pagination.mainProductLimit = 8
      state.pagination.productSkip = 0
      state.pagination.mainProductSkip = 0
      await searchItems(false)
    } catch (e) {
      state.loading = false
      console.log(e)
    }
  }

  const searchItems = async (addToList) => {
    try {
      state.loading = true
      const res = await server.call('searchAllProducts', {
        ...state.query,
        ...state.pagination,
        category: state.category ? [state.category._id] : null,
        productFamily: state.productFamily ? [state.productFamily._id] : null,
        productFields: ['_id', 'product_name', 'categories', 'discount', 'prices', 'img', 'tasteImg', 'packaging', 'unitPrice', 'taste', 'stock', 'url', 'product_code', 'wishListAdded', 'overallRating', 'ratingCount', 'gifts', 'nrOfAllowedGifts', 'nrOfPurchases']
      })
      if (state.pagination.mainProductSkip === 0 && state.pagination.productSkip === 0) {
        state.pagination.productRowsNumber = res.productCount
        state.pagination.mainProductRowsNumber = res.mainProductCount
      }
      if (addToList === true) {
        state.products = [...state.products, ...res.items]
      } else {
        state.products = res.items
      }
      state.loaded = true
      state.loading = false
    } catch (e) {
      state.loading = false
      console.log(e)
    }
  }

  return {
    ...toRefs(state),
    loadProductsPage,
    searchItems
  }
}
