<script>
import { useLayout } from 'src/composables/useLayout'
import { computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import S3Image from 'components/general/S3Image'
import { useFilters } from 'src/composables/useFilters'
import ProductGiftSelector from 'pages/ProductGiftSelector'
import ProductGiftItem from 'components/product/ProductGiftItem'
import { useCart } from 'src/composables/useCart'
export default {
  name: 'PopupItem',
  components: { ProductGiftItem, ProductGiftSelector, S3Image },
  props: ['cartItem', 'smallCartItem', 'itemIndex', 'cartLength', 'hideSeparator', 'showRemove'],
  emits: ['clicked'],
  setup (props, { emit }) {
    const { translate } = useLayout()
    const route = useRoute()
    const router = useRouter()
    const { removeProduct, updateCartItemGifts } = useCart()
    const { prettifyCurrency } = useFilters()

    const openSelector = ref(false)
    const showMainProductEditor = ref(false)

    const removeCartItemFromCart = () => {
      removeProduct({ product: props.cartItem.product.product_code, type: props.cartItem.type, mainProduct: props.cartItem.mainProduct ? props.cartItem.mainProduct._id : null })
    }

    const removeGiftFromProduct = (giftCode) => {
      const gifts = props.cartItem.gifts.map(gift => {
        if (gift.product.product_code === giftCode) {
          return {
            ...gift,
            quantity: 0
          }
        }
        return {
          ...gift
        }
      })
      updateCartItemGifts({ product: props.cartItem.product.product_code, type: props.cartItem.type, mainProduct: props.cartItem.mainProduct ? props.cartItem.mainProduct._id : null, gifts: gifts })
    }

    function openItem () {
      if (props.cartItem.type === 'PRODUCT' && route.path !== `/termek/${props.cartItem.product.url}`) {
        router.push(`/termek/${props.cartItem.product.url}`)
      }
      if (props.cartItem.type === 'MAIN_PRODUCT' && route.path !== `/fo-termek/${props.cartItem.mainProduct.url.name}`) {
        router.push(`/fo-termek/${props.cartItem.mainProduct.url.name}`)
      }
      if (props.smallCartItem) {
        emit('clicked')
      }
    }

    const displaySeparator = computed(() => {
      if (props.hideSeparator === true || (props.cartLength === 1 && props.cartItem.gifts && props.cartItem.gifts.length === 0) || (props.itemIndex === props.cartLength - 1 && props.cartItem.gifts && props.cartItem.gifts.length === 0)) {
        return 'display: none'
      }
      return ''
    })

    return {
      translate,
      openSelector,
      showMainProductEditor,
      removeGiftFromProduct,
      openItem,
      displaySeparator,
      prettifyCurrency,
      removeCartItemFromCart
    }
  }
}
</script>

<template lang="pug">
.row(:class="$q.screen.lt.sm ? 'q-px-md' : 'q-px-md'").cursor-pointer
  .full-width(v-if="cartItem.type === 'MAIN_PRODUCT'")
    .q-mt-sm(style="position: relative;")
      .bigCloseBtn(@click="removeCartItemFromCart()" v-if="showRemove")
        svg(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='20' height='20' fill="white")
          path(fill='none' d='M0 0h24v24H0z')
          path(d='M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z')
        q-tooltip {{ translate('cart.removeItem') }}
      .row.flex.items-center.no-wrap.q-pb-sm.popup-item
        .row.no-wrap.row.items-center
          .flex.flex-center.cursor-pointer(@click="openItem" style="background: #DFE0E3;" :class="smallCartItem && $q.screen.lt.sm ? 'q-mr-sm' : 'q-mr-md'" :style="$q.screen.lt.md || smallCartItem ? 'height: 90px; width: 90px;' : 'height: 100px; width: 100px;'")
            div
              s3-image(:ratio="1" :src="cartItem.product.img" img-style="width: 90px; height: 90px;")
              q-tooltip {{translate('product.openItem')}}
        .row.items-center.justify-between.q-mr-md(v-if="$q.screen.gt.sm && !smallCartItem")
          q-chip.quantityCircle {{ cartItem.quantity + translate('product.piece')}}
        .row.flex-1.full-width.no-wrap
          .row.justify-between.items-center.no-wrap.full-width
            .row.items-center.full-width(:class="smallCartItem ? 'q-pr-sm q-pl-xs' : ''")
              .popupItemName(v-if="!smallCartItem" @click="openItem" :style="$q.screen.lt.sm ? 'font-weight: 400' : ''") {{ cartItem.product.product_name }}
              .popupItemName(v-else @click="openItem") {{ cartItem.product.product_name }}
              .row.items-center.full-width(v-if="$q.screen.lt.md || smallCartItem" :class="smallCartItem ? 'justify-between no-wrap' : ''")
                .row.items-center
                  .column.justify-center.no-wrap
                    .cart-item-quantity(v-if="smallCartItem") {{ cartItem.product.packaging }}
                    .cart-item-taste(v-if="smallCartItem") {{ cartItem.product.taste }}
                .no-wrap.lt-md(:class="smallCartItem ? 'column items-end self-end' : 'row items-center q-pt-xs'" style="min-width: fit-content")
                  .normalPrice(v-if="cartItem.product && smallCartItem" :style="smallCartItem ? 'font-size: 15px !important; font-weight: 400;' : ''") {{ cartItem.quantity }}db
                  .normalPrice(v-if="cartItem.product" :class="cartItem.product.hasDiscount ? 'text-strike' : ''" :style="smallCartItem ? `font-size: ${cartItem.product.hasDiscount ? 13: 15}px !important; font-weight: 600` : `font-size: ${cartItem.product.hasDiscount ? 14: 17}px;`") {{ prettifyCurrency(cartItem.product.prices.gross_price * cartItem.quantity * cartItem.quantity) }}
                  .normalPrice.text-red.q-ml-md(v-if="cartItem.product.hasDiscount" :style="smallCartItem ? 'font-size: 15px !important; font-weight: 600' : 'font-size: 17px;'") {{ prettifyCurrency(cartItem.product.discount.prices.gross_price * cartItem.quantity) }}
            .row.justify-end.no-wrap.q-pr-sm.items-center(v-if="$q.screen.gt.sm && !smallCartItem" style="min-width: fit-content")
              .normalPrice.q-pl-xs(:class="cartItem.product.hasDiscount ? 'text-strike' : ''" :style="cartItem.product.hasDiscount ? 'font-size: 14px !important' : 'font-size: 16px !important'" v-if="cartItem") {{ prettifyCurrency(cartItem.product.prices.gross_price * cartItem.quantity) }}
              .normalPrice.text-red.q-ml-md(v-if="cartItem.product.hasDiscount" :style="smallCartItem ? 'font-size: 15px !important; font-weight: 600' : 'font-size: 17px;'") {{ prettifyCurrency(cartItem.product.discount.prices.gross_price * cartItem.quantity) }}
            .no-wrap.gt-sm.q-pr-sm.full-height(v-if="smallCartItem" :class="smallCartItem ? 'column justify-center' : 'row justify-end items-center'" style="min-width: fit-content")
              .small-cart-quantity.q-pb-md(v-if="smallCartItem") {{ cartItem.quantity }}db
              .normalPrice(style="line-height: 18px;" :style="`font-size: ${cartItem.product.hasDiscount ? 13: 15}px !important`" :class="`${smallCartItem ? '' : 'q-pr-sm'} ${cartItem.product.hasDiscount ? 'text-strike' : ''}`") {{ prettifyCurrency(cartItem.product.prices.gross_price * cartItem.quantity) }}
              .normalPrice.text-red(v-if="cartItem.product.hasDiscount" style="line-height: 18px;" ) {{ prettifyCurrency(cartItem.product.discount.prices.gross_price * cartItem.quantity) }}
              .normalPrice.q-pl-xs(v-if="!smallCartItem")  /{{translate('products.piece')}}
          .flex.flex-center.lt-sm(v-if="!smallCartItem")
            q-chip.dark-color(style="font-weight: 500;") {{ cartItem.quantity }}db
      .row(style="height: 1px;" :style="displaySeparator")
        q-separator.full-width(size="1px")

  .full-width(v-else-if="cartItem.type === 'PRODUCT'").relative-position
    .bigCloseBtn(@click="removeCartItemFromCart()" v-if="showRemove")
      svg(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='20' height='20' fill="white")
        path(fill='none' d='M0 0h24v24H0z')
        path(d='M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z')
      q-tooltip {{ translate('cart.removeItem') }}
    .q-mt-sm(style="position: relative;")
      .row.flex.items-center.no-wrap.q-pb-sm.popup-item
        .row.no-wrap.row.items-center
          .flex.flex-center.cursor-pointer(@click="openItem" style="background: #DFE0E3;" :class="smallCartItem && $q.screen.lt.sm ? 'q-mr-sm' : 'q-mr-md'" :style="$q.screen.lt.md || smallCartItem ? 'height: 90px; width: 90px;' : 'height: 100px; width: 100px;'")
            div
              s3-image(:ratio="1" :src="cartItem.product.img" img-style="width:90px; height: 90px;")
              q-tooltip {{translate('product.openItem')}}
        .row.items-center.justify-between.q-mr-md(v-if="$q.screen.gt.sm && !smallCartItem")
          q-chip.quantityCircle {{ cartItem.quantity + translate('product.piece')}}
        .row.flex-1.full-width.no-wrap
          .row.justify-between.items-center.no-wrap.full-width
            .row.items-center.full-width(:class="smallCartItem ? 'q-pr-sm q-pl-xs' : ''")
              .popupItemName(v-if="!smallCartItem" @click="openItem" :style="$q.screen.lt.sm ? 'font-weight: 400' : ''") {{ cartItem.product.product_name }}
              .popupItemName(v-else @click="openItem") {{ cartItem.product.product_name }}
              .row.items-center.full-width(v-if="$q.screen.lt.md || smallCartItem" :class="smallCartItem ? 'justify-between no-wrap' : ''")
                .row.items-center
                  .column.justify-center.no-wrap
                    .cart-item-quantity(v-if="smallCartItem") {{cartItem.product.packaging}}
                    .cart-item-taste(v-if="smallCartItem") {{cartItem.product.taste}}
                .no-wrap.lt-md(:class="smallCartItem ? 'column items-end self-end' : 'row items-center q-pt-xs'" style="min-width: fit-content")
                  .normalPrice(v-if="cartItem.product && smallCartItem" :style="smallCartItem ? 'font-size: 15px !important; font-weight: 400;' : ''") {{ cartItem.quantity }}db
                  .row.items-center
                    .normalPrice(v-if="cartItem.product" :class="cartItem.product.hasDiscount ? 'text-strike' : ''" :style="smallCartItem ? `font-size: ${cartItem.product.hasDiscount ? 13: 15}px !important; font-weight: 600` : `font-size: ${cartItem.product.hasDiscount ? 14: 17}px;`") {{ prettifyCurrency(cartItem.product.prices.gross_price * cartItem.quantity) }}
                    .normalPrice.text-red.q-ml-md(v-if="cartItem.product && cartItem.product.hasDiscount" :style="smallCartItem ? 'font-size: 15px !important; font-weight: 600' : 'font-size: 17px;'") {{ prettifyCurrency(cartItem.product.discount.prices.gross_price * cartItem.quantity) }}
            .column.no-wrap.q-px-sm.items-center(v-if="$q.screen.gt.sm && !smallCartItem" style="min-width: fit-content")
              .normalPrice(:style="cartItem.product.hasDiscount ? 'font-size: 14px !important' : ''" :class="cartItem.product.hasDiscount ? 'text-strike' : ''" v-if="cartItem.product") {{ prettifyCurrency(cartItem.product.prices.gross_price) }}
              .normalPrice.text-red( v-if="cartItem.product && cartItem.product.hasDiscount") {{ prettifyCurrency(cartItem.product.prices.gross_price) }}
            .no-wrap.gt-sm.q-pr-sm.full-height(v-if="smallCartItem" :class="smallCartItem ? 'column justify-center' : 'row justify-end items-center'" style="min-width: fit-content")
              .small-cart-quantity.q-pb-md(v-if="smallCartItem") {{ cartItem.quantity }}db
              .normalPrice(:style="cartItem.product.hasDiscount ? 'font-size: 14px !important' : ''" style="line-height: 18px;" :class="`${smallCartItem ? '' : 'q-pr-sm'} ${cartItem.product.hasDiscount ? 'text-strike' : ''}`") {{ prettifyCurrency(cartItem.product.prices.gross_price * cartItem.quantity) }}
              .normalPrice.text-red( style="line-height: 18px;") {{ prettifyCurrency(cartItem.product.prices.gross_price * cartItem.quantity) }}
              .normalPrice(v-if="!smallCartItem")  /{{translate('products.piece')}}
          .flex.flex-center.lt-sm(v-if="!smallCartItem")
            q-chip.dark-color(style="font-weight: 500;") {{ cartItem.quantity }}db
      .row(style="height: 1px;" :style="displaySeparator")
        q-separator.full-width(size="1px")
  .full-width.relative-position(v-if="cartItem.gifts && cartItem.gifts.length > 0" v-for="(gift, giftIndex) in cartItem.gifts" :key="gift._id")
    product-gift-item(@clicked="$emit('clicked')" :gift="gift" :show-price="true" :popup-item="true" :small-cart-item="smallCartItem")
    .bigCloseBtn.gt-sm(@click="removeGiftFromProduct(gift.product.product_code)" v-if="showRemove")
      svg(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='20' height='20' fill="white")
        path(fill='none' d='M0 0h24v24H0z')
        path(d='M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z')
      q-tooltip {{ translate('cart.removeItem') }}
</template>

<style lang="sass" scoped>
.chip
  border: 2px solid rgb(50, 50, 50)
  background: transparent !important
.chip-sm
  border: 1px solid rgb(50, 50, 50)
  background: transparent !important
.quantityCircle
  border: 2px solid #55575B
  border-radius: 56px
  background: white
  padding: 5px 10px 5px 10px
  font-size: 16px
  display: flex
  justify-content: center
  align-items: center
  color: #55575B
  font-weight: 500
.popupItemName
  font-weight: 500
  color: #4D4F53
  line-height: 17px
  font-size: 16px
.normalPrice
  font-size: 16px !important
  color: #4d4f53
  font-weight: 500
  text-align: right
.small-cart-quantity
  color: #4d4f53
  text-align: right
  font-weight: 400
  font-size: 16px
.cart-item-taste, .cart-item-quantity
  font-weight: 400
  color: #4D4F53
.bigCloseBtn
  z-index: 1000
  cursor: pointer
  background: #4E4F53
  height: 25px
  width: 25px
  border-radius: 100px
  display: flex
  justify-content: center
  align-items: center
  position: absolute
  top: 0px
  left: -5px
</style>
