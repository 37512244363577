<script>
import S3Image from './S3Image'
import { computed, defineComponent } from 'vue'
import { useLayout } from 'src/composables/useLayout'
import PromotionLine from 'components/layout/PromotionLine'

export default defineComponent({
  name: 'SelectItemModal',
  components: { PromotionLine, S3Image },
  props: ['items', 'visible', 'selectedItem', 'title', 'fastPurchase'],
  setup (props, { emit }) {
    const { translate, thumbStyle, barStyle } = useLayout()

    const scrollAreaHeight = computed(() => {
      let res = 90
      if (props.items) {
        res = props.items.length * 110
      }
      return res
    })
    const computedSelectedItem = computed({
      get () {
        return props.selectedItem
      },
      set (val) {
        emit('update:selectedItem', val)
      }
    })
    const computedVisible = computed({
      get () {
        return props.visible
      },
      set (val) {
        emit('update:visible', val)
      }
    })

    return {
      translate,
      thumbStyle,
      barStyle,
      scrollAreaHeight,
      computedSelectedItem,
      computedVisible
    }
  }
})
</script>

<template lang="pug">
q-dialog(v-model='computedVisible' :seamless="$q.screen.lt.sm")
  q-card(:class="$q.screen.lt.sm ? 'no-shadow full-width' : ''" :style="$q.screen.lt.sm ? 'position: absolute !important; top: 55px; height: auto; max-height: 100%; border-radius: 0;' : 'border-radius: 15px; width: 560px; max-width: 560px !important;'")
    promotion-line.lt-sm
    .selectItemModal.overflow-hidden.full-width
      .row.relative-position.justify-center.selectItemHeader
        .dark-color.text-center.item-select-title {{ title }}:
        .absolute-top-right.row.items-center
          .cursor-pointer(@click="computedVisible = false" style="padding: 10px 10px 0 0")
            svg(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='30' height='30' fill="#242424")
              path(fill='none' d='M0 0h24v24H0z')
              path(d='M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z')
      .scrollAreaWrapper
        q-scroll-area.scroll-item(:visible="true" :style="$q.screen.lt.sm ? 'height: calc(100vh - 145px);' : `max-height: calc(90vh - 100px); width: 100% !important; height: ${scrollAreaHeight}px !important; margin: 0 20px;`" :thumb-style="thumbStyle" :bar-style="barStyle").full-width
          q-card.item-list(v-for="item in items" :key="item.value" @click="computedSelectedItem = item.value" :style="`background: ${computedSelectedItem === item.value ? '#D2E1FA' : ''}; -webkit-box-shadow: inset 0px 0px 0px ${computedSelectedItem === item.value ? '2px #0370F7' : '1px #E0E0E0'}, 0px 2px 2px 0px #dfe0e3; box-shadow: inset 0px 0px 0px ${computedSelectedItem === item.value ? '2px #0370F7' : '1px #E0E0E0'}, 0px 2px 2px 0px #dfe0e3 !important;`" round)
            .flex.items-center.no-wrap
              s3-image(:ratio="1" :src="item.img" :img-style="`width: ${$q.screen.gt.sm ? 60 : 50}px;`" v-if="item.img" :class="$q.screen.lt.sm ? 'q-mr-sm' : 'q-mr-lg'")
              .text-500.dark-color(:style="$q.screen.lt.sm ? 'font-size: 15px' : 'font-size: 18px'") {{item.label}}
            div.q-pl-sm
              .box(:class="computedSelectedItem === item.value ? 'selectedBox' : ''")
                .innerBox(v-if="computedSelectedItem === item.value")
</template>

<style lang="sass">
.q-dialog--seamless
  .q-dialog__inner, .q-dialog__inner--minimized
    padding: 0 !important
    max-height: 100% !important
.selectItemModal
  width: 560px
  .scrollAreaWrapper
    @media screen and (min-width: 1024px)
      padding: 0 20px
  .item-select-title
    font-size: 32px
    padding: 20px 0
    font-weight: 600
    @media screen and (max-width: 600px)
      font-size: 22px !important
      align-self: center
      padding: 10px 0 !important
  @media screen and (max-width: 600px)
    width: initial !important
  .item-list
    border-radius: 5px !important
    padding: 15px 20px
    min-height: 60px
    @media screen and (max-width: 600px)
      margin: 6px !important
  .scroll-item
    padding: 0 12px 20px 12px
    @media screen and (max-width: 1024px)
      padding-bottom: 45px
    .q-card
      cursor: pointer
      margin-bottom: 16px
      margin-right: 16px
      display: flex
      flex-direction: row
      justify-content: space-between
      align-items: center
      flex-wrap: nowrap
  .scroll-item > .scroll
    align-items: start !important
  .text-color
    color: #4D4F53
  .box
    width: 35px
    height: 35px
    border-radius: 100px
    background: #F2F2F2
    border: 1px solid #D8D8D8
    cursor: pointer
    display: flex
    justify-content: center
    align-items: center
    box-shadow: 0px 1px 1px 0px rgba(223,224,227,1)
    -webkit-box-shadow: 0px 1px 1px 0px rgba(223,224,227,1)
    -moz-box-shadow: 0px 1px 1px 0px rgba(223,224,227,1)
  .selectedItem
    background: #D2E1FA
  .selectedBox
    width: 35px
    height: 35px
    border-radius: 100px
    background: #D2E1FA
    border: 2px solid #0370F7
    cursor: pointer
    box-shadow: 0 1px 1px 0 rgba(223,224,227,1)
    -webkit-box-shadow: 0 1px 1px 0 rgba(223,224,227,1)
    -moz-box-shadow: 0 1px 1px 0 rgba(223,224,227,1)
    .innerBox
      width: 21px
      height: 21px
      border-radius: 100px
      background: #0370F7
.selectItemHeader
  height: 80px
  @media screen and (max-width: 1024px)
    height: 50px !important
</style>
