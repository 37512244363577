<script>
import { defineComponent } from 'vue'
import { useLayout } from 'src/composables/useLayout'
import { useFilters } from 'src/composables/useFilters'

export default defineComponent({
  name: 'PromotionLine',
  setup () {
    const { translate, randomizedDiscount, country } = useLayout()
    const { prettifyNumber } = useFilters()
    return {
      translate,
      randomizedDiscount,
      country,
      prettifyNumber
    }
  }
})
</script>

<template lang="pug">
.row.justify-center(v-if="randomizedDiscount >= 0").promotionLineContainer
  .q-px-sm.lt-md
    .row.no-wrap.full-width.q-px-sm( :style="`font-size: 13px; height: 40px`")
      .flex.flex-center(v-if="randomizedDiscount === 0")
        .row.flex.flex-center.full-width(v-if="country")
          .row.q-pr-sm.items-center
            svg(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='18' height='18' :style="`height: 16px; width: 16px;`" fill="#BEBFC1")
              path(fill='none' d='M0 0h24v24H0z')
              path(d='M8.965 18a3.5 3.5 0 0 1-6.93 0H1V6a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2h3l3 4.056V18h-2.035a3.5 3.5 0 0 1-6.93 0h-5.07zM15 7H3v8.05a3.5 3.5 0 0 1 5.663.95h5.674c.168-.353.393-.674.663-.95V7zm2 6h4v-.285L18.992 10H17v3zm.5 6a1.5 1.5 0 1 0 0-3.001 1.5 1.5 0 0 0 0 3.001zM7 17.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0z')
          .dark-color.text-600 {{ prettifyNumber(country.free_delivery_limit_gross, 0) }}
          .q-pl-xs(style="color: #5C5C5C") {{ translate('home.afterPrice') }}
      .row.flex.text-center.items-center.justify-center(style="color: #5C5C5C" v-if="randomizedDiscount === 1")
        .row.q-pr-sm.items-center
          svg(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='18' height='18' :style="`height: 16px; width: 16px; opacity:0.4;`")
            path(fill='none' d='M0 0h24v24H0z')
            path(d='M15 2a4 4 0 0 1 3.464 6.001L23 8v2h-2v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V10H1V8l4.536.001A4 4 0 0 1 12 3.355 3.983 3.983 0 0 1 15 2zm-4 8H5v9h6v-9zm8 0h-6v9h6v-9zM9 4a2 2 0 0 0-.15 3.995L9 8h2V6a2 2 0 0 0-1.697-1.977l-.154-.018L9 4zm6 0a2 2 0 0 0-1.995 1.85L13 6v2h2a2 2 0 0 0 1.995-1.85L17 6a2 2 0 0 0-2-2z')
        | {{translate('home.selectableGift')}}
      .row.items-center.justify-center(v-if="randomizedDiscount === 2")
        .row.justify-center.full-width.items-center
          .row.q-pr-sm.items-center
            svg(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='18' height='18' :style="`height: 16px; width: 16px; opacity:0.4;`")
              path(fill='none' d='M0 0h24v24H0z')
              path(d='M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z')
          span(style="color: #5C5C5C")
            | {{translate('home.shippingPriceFrom')}}
          span.dark-color.text-600.q-px-sm
            | 1090 {{translate('home.forAmount')}}
          span(style="color: #5C5C5C")
            | {{translate('home.orForFree')}}
  .contentWidth.gt-sm
    .full-width.q-px-md
      .row.no-wrap.full-width.justify-between(style="font-size: 16px; height: 56px")
        .flex.flex-center
          .row.flex.flex-center.full-width(v-if="country")
            .row.q-pr-sm.items-center
              svg(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='18' height='18' style="height: 24px; width: 24px;" fill="#BEBFC1")
                path(fill='none' d='M0 0h24v24H0z')
                path(d='M8.965 18a3.5 3.5 0 0 1-6.93 0H1V6a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2h3l3 4.056V18h-2.035a3.5 3.5 0 0 1-6.93 0h-5.07zM15 7H3v8.05a3.5 3.5 0 0 1 5.663.95h5.674c.168-.353.393-.674.663-.95V7zm2 6h4v-.285L18.992 10H17v3zm.5 6a1.5 1.5 0 1 0 0-3.001 1.5 1.5 0 0 0 0 3.001zM7 17.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0z')
            .dark-color.text-600 {{ prettifyNumber(country.free_delivery_limit_gross, 0) }}
            .q-pl-xs(style="color: #5C5C5C") {{ translate('home.afterPrice') }}
        .row.items-center.justify-center
          .row.justify-center.full-width.items-center
            .row.q-pr-sm.items-center
              svg(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='18' height='18' style="height: 24px; width: 24px; opacity:0.4;")
                path(fill='none' d='M0 0h24v24H0z')
                path(d='M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z')
            span(style="color: #5C5C5C")
              | {{translate('home.shippingPriceFrom')}}
            span.dark-color.text-600.q-px-sm
              | 1090 {{translate('home.forAmount')}}
            span(style="color: #5C5C5C")
              | {{translate('home.orForFree')}}
</template>

<style scoped lang="sass">
.promotionLineContainer
  background: #ECECEE
  height: 56px
  @media screen and (max-width: 1023px)
    height: 40px
</style>
