<script>
import { useLayout } from 'src/composables/useLayout'
import { useValidations } from 'src/composables/useValidations'
import { computed, ref } from 'vue'
import { useFilters } from 'src/composables/useFilters'
import S3Image from 'components/general/S3Image'
import ProductInfoModal from 'components/product/ProductInfoModal'
import ProductGiftItem from 'components/product/ProductGiftItem'
import SelectGiftModal from 'components/product/SelectGiftModal'

export default {
  name: 'ProductGiftSelector',
  components: { SelectGiftModal, ProductGiftItem, ProductInfoModal, S3Image },
  props: ['product', 'quantityToBuy', 'showPrice', 'disable', 'cartItemType'],
  setup (props, { emit }) {
    const { translate } = useLayout()
    const { validatePassword } = useValidations()
    const { prettifyNumber } = useFilters()

    const showGiftSelector = ref(false)
    const selectedProduct = ref(null)
    const showProductInfo = ref(false)

    const maxGifts = computed(() => {
      return props.quantityToBuy * props.product.nrOfAllowedGifts
    })
    const nrOfGifts = computed(() => {
      let res = 0
      selectedGifts.value.forEach(e => {
        res += e.quantity
      })
      while (res > maxGifts.value) {
        const asd = computedProduct.value
        asd.gifts.reverse().map(gift => {
          if (gift.quantity > 0 && res > maxGifts.value) {
            gift.quantity--
            res--
          }
          return gift
        })
      }
      return res
    })
    const selectedGifts = computed(() => {
      if (props.product && props.product.gifts) {
        const res = props.product.gifts.filter(e => e.quantity > 0)
        if (res) {
          return res
        }
        return []
      }
      return []
    })

    const computedProduct = computed({
      get () {
        return props.product
      },
      set (val) { emit('update:product', val) }
    })

    function removeProductGift (giftToRemove) {
      computedProduct.value.gifts = props.product.gifts.map(gift => {
        if (gift.product.product_code === giftToRemove.product.product_code) {
          return {
            ...gift,
            quantity: 0
          }
        }
        return gift
      })
      emit('selectedGifts', selectedGifts.value)
    }

    function removeAllGifts () {
      computedProduct.value.gifts = props.product.gifts.map(gift => { return { ...gift, quantity: 0 } })
      emit('selectedGifts', [])
    }

    return {
      translate,
      validatePassword,
      showGiftSelector,
      selectedProduct,
      showProductInfo,
      removeProductGift,
      removeAllGifts,
      nrOfGifts,
      selectedGifts,
      maxGifts,
      prettifyNumber,
      computedProduct
    }
  }
}
</script>

<template lang="pug">
.productGiftContainer(v-if="product && product.gifts && product.gifts.length > 0 && maxGifts > 0")
  .extra-products
    .row.items-center
      .chooseLabel {{translate('products.gift')}}
      // .cursor-pointer(@click="removeAllGifts()" v-if="selectedGifts && selectedGifts.length > 0")
        q-tooltip {{ translate('products.deleteAllGift') }}
        q-chip
          svg(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='18' height='18' fill="#616161")
            path(fill='none' d='M0 0h24v24H0z')
            path(d='M17 6h5v2h-2v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8H2V6h5V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3zm1 2H6v12h12V8zm-9 3h2v6H9v-6zm4 0h2v6h-2v-6zM9 4v2h6V4H9z')
    .row.items-center.cursor-pointer(@click="showGiftSelector = !showGiftSelector")
      .q-ml-sm.dark-color(style="font-size: 16px") {{translate('products.giftSelected')}}:
      q-chip.q-ml-sm.text-500.text-white(size="10px" style="font-size: 17px; background: #f1222a; max-height: 30px;") {{nrOfGifts}}/{{maxGifts}}
  div
    .selectedGiftsContainer(v-if="selectedGifts && selectedGifts.length > 0" v-for="(gift, index) in selectedGifts" :key="index")
      product-gift-item(:gift="gift" @showSelector="showGiftSelector = !showGiftSelector" :show-price="showPrice" @remove="removeProductGift(gift)" @openGiftSelector="showGiftSelector = !showGiftSelector")
    .row.items-center.justify-between.cursor-pointer.full-width.gift-selector(v-if="nrOfGifts < maxGifts && !disable" @click="showGiftSelector = !showGiftSelector")
      .row.items-center
        svg.q-ml-sm#Layer_1(version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='42px' height='42px' viewBox='0 0 47 47' enable-background='new 0 0 47 47' xml:space='preserve')
          image#image0(width='47' height='47' x='0' y='0' href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC8AAAAvCAAAAABWTMxwAAAABGdBTUEAALGPC/xhBQAAACBjSFJN\
          AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAnRSTlMAAHaTzTgAAAACYktH\
          RAD/h4/MvwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAd0SU1FB+ULGgYTDK7913AAAAD8SURBVEjH\
          3ZZJFsQgCAUhzy33PygX6EUGGf7XZNvuGqswQYOtAoaLiIihKWU0MZTT0NAVDQyFuEXVGO8FQIY2\
          Os72mNaZjaHwNeMjZ0NLxDeG1hV9vYb2hEtD0VlZGHf+so3NKPk3xqyfhlIyI44jrlnOZj2cduU3\
          drjSGic17h9+A7DuT3TMQDPQhzZEiAFfN/LJYOMoJWg1KYFRE5jwHUH8NGD/OVDQKI556NPn4fDb\
          /H/G+0vW5aqnk81p9FP/rfH0B9qXSX8T9PFmPvdPAUbke3/u0cnj/h9nLPD8fsmGS/rQ4P2VVo+D\
          3o/y+f6Vr/d7Nt78f5gGPCE/3xlSVZVjmycAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjEtMTEtMjZU\
          MDY6MTk6MTIrMDA6MDDo13pvAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIxLTExLTI2VDA2OjE5OjEy\
          KzAwOjAwmYrC0wAAAABJRU5ErkJggg==')
        .row.q-ml-md.flex.items-center(style="flex: 1; font-size: 16px;")
          .q-mr-xs {{translate('products.chooseFirst')}}
          .text-600.q-mr-xs {{ maxGifts }}
          .text-600.q-mr-xs {{translate('products.chooseSecond')}}
          .q-mr-xs {{translate('products.chooseThird')}}
          .row
            .text-600.q-mr-xs(v-if="product && product.gifts && product.gifts[0]") {{ prettifyNumber(product.gifts[0].prices.gross_price * maxGifts, 0) }}
            .text-600 {{translate('products.chooseFourth')}}
        .flex.flex-center(style="width: 36px")
          svg(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='30' height='30' fill="white")
            path(fill='none' d='M0 0h24v24H0z')
            path(d='M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z')

  select-gift-modal(@open="selectedProduct = $event; showProductInfo = true;" v-model:selected-product="selectedProduct" @addToCart="$emit('addToCart')" v-if="product.gifts && product.gifts.length > 0" :popup-gift="true" @updated="$emit('selectedGifts', $event)" v-model:items="computedProduct.gifts" v-model:visible="showGiftSelector" :max-gifts="maxGifts")
  product-info-modal(v-model:visible="showProductInfo" :product="selectedProduct" v-if="selectedProduct")
</template>

<style lang="sass" scoped>
.chip
  border: 2px solid rgb(50, 50, 50)
  background: transparent !important
.chip-sm
  border: 1px solid rgb(50, 50, 50)
  background: transparent !important
.gift-selector
  min-height: 70px
  background: linear-gradient(90deg, #106ceb 0%, #f1222a 100%)
  border-radius: 8px
  color: white
  padding: 20px
  @media screen and (max-width: 1023px)
    padding: 12px !important
.chooseLabel
  font-weight: 500
  font-size: 16px
  color: #4d4e53
.productGiftContainer
  .extra-products
    display: flex
    flex-direction: row
    align-items: center
    margin-bottom: 12px
    justify-content: space-between
    @media screen and (max-width: 599px)
      margin-bottom: 6px !important
  width: 100%
  margin-top: 12px
  margin-bottom: 20px
  @media screen and (max-width: 1023px)
    margin-bottom: 12px !important
.selectedGiftsContainer
  padding-bottom: 20px
  @media screen and (max-width: 1023px)
    padding-bottom: 0px !important
</style>
