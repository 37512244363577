<script>
import { computed, defineComponent, ref, watch } from 'vue'
import { useLayout } from 'src/composables/useLayout'
import { useCart } from 'src/composables/useCart'
import { useRoute, useRouter } from 'vue-router'
import { useQuasar } from 'quasar'
import { useAuth } from 'src/composables/useAuth'
import { server } from 'boot/meteor'
import { useFilters } from 'src/composables/useFilters'
import S3Image from 'components/general/S3Image'
import SelectItemModal from 'components/general/SelectItemModal'
import FastPurchaseModal from 'components/general/FastPurchaseModal'
import DiscountBadge from 'components/product/DiscountBadge'

export default defineComponent({
  name: 'ProductListItem',
  components: { DiscountBadge, FastPurchaseModal, SelectItemModal, S3Image },
  props: ['item', 'type', 'wide', 'hover'],
  setup (props, { emit }) {
    const { translate } = useLayout()
    const { fullShoppingCart, addProduct, refreshFullCartItems } = useCart()
    const { userDetailsLoaded, showLogin } = useAuth()
    const router = useRouter()
    const route = useRoute()
    const $q = useQuasar()
    const { prettifyNumber, prettifyCurrency } = useFilters()
    const showFastPurchaseEditor = ref(false)
    const productHeight = ref(0)
    const selectedProduct = ref(null)

    watch(() => props.item, val => {
      if (props.type === 'MAIN_PRODUCT') {
        selectedProduct.value = val.products[0]
      } else {
        selectedProduct.value = val
      }
    }, { immediate: true, deep: true })

    const doFastPurchase = (quantityToBuy) => {
      switch (props.type) {
        case 'PRODUCT': {
          addProduct({ quantity: 1, type: props.type, product: props.item.product_code })
          refreshFullCartItems()
          showFastPurchaseEditor.value = false
          break
        }
        case 'MAIN_PRODUCT': {
          if (showFastPurchaseEditor.value) {
            addProduct({ quantity: quantityToBuy || 1, type: props.type, mainProduct: props.item._id, product: selectedProduct.value.product_code, gifts: [] })
            refreshFullCartItems()
            showFastPurchaseEditor.value = false
            break
          } else {
            showFastPurchaseEditor.value = true
            break
          }
        }
      }
    }

    const openItem = () => {
      emit('clicked')
      if (props.type === 'PRODUCT' && route.path !== `/termek/${props.item.url}`) {
        const path = `/termek/${props.item.url}`
        if (route.path === path) {
          emit('clicked')
        } else {
          router.push(path)
          emit('clicked')
        }
      }
      if (props.type === 'MAIN_PRODUCT' && route.path !== `/fo-termek/${props.item.url}`) {
        const path = `/fo-termek/${props.item.url}`
        if (route.path === path) {
          emit('clicked')
        } else {
          router.push(path)
          emit('clicked')
        }
      }
    }
    const getURL = () => {
      if (props.type === 'PRODUCT') {
        return `/termek/${props.item.url}`
      }
      if (props.type === 'MAIN_PRODUCT') {
        return `/fo-termek/${props.item.url}`
      }
    }
    const resizeProductListItem = () => {
      if ($q.screen.gt.sm) {
        return 'height: 434px'
      }
      if ($q.screen.gt.xs) {
        return 'height: 2912px'
      }
      if ($q.screen.lt.sm) {
        return 'height: 916px'
      }
      if ($q.screen.lt.md) {
        return 'padding-bottom: 16px;'
      } else {
        return 'flex: 1;'
      }
    }
    const addToWishList = async () => {
      if (!userDetailsLoaded.value) {
        showLogin.value = true
        $q.notify({ type: 'info', message: translate('product.needToLogin') })
        return
      }
      try {
        await server.call('addProductToWishList', { _id: selectedProduct.value.product_code })
        selectedProduct.value.wishListAdded = true
        $q.notify({ type: 'positive', message: translate('product.addedToWishlist') })
      } catch (e) {
        console.log(e)
        $q.notify({ type: 'negative', message: e.reason })
      }
    }
    const removeFromWishList = async () => {
      if (!userDetailsLoaded.value) {
        showLogin.value = true
        $q.notify({ type: 'info', message: translate('product.needToLogin') })
        return
      }
      try {
        await server.call('removeProductFromWishList', { _id: selectedProduct.value.product_code })
        selectedProduct.value.wishListAdded = false
        $q.notify({ type: 'positive', message: translate('product.removedFromWishlist') })
      } catch (e) {
        console.log(e)
        $q.notify({ type: 'negative', message: e.reason })
      }
    }

    const isClothing = computed(() => { return selectedProduct.value && selectedProduct.value.categories ? selectedProduct.value.categories.some(e => e.isClothing) : false })

    return {
      translate,
      isClothing,
      productHeight,
      fullShoppingCart,
      doFastPurchase,
      openItem,
      getURL,
      resizeProductListItem,
      addToWishList,
      removeFromWishList,
      showFastPurchaseEditor,
      prettifyNumber,
      prettifyCurrency,
      selectedProduct
    }
  }
})
</script>

<template lang="pug">
.search-result-item(:class="hover ? 'product-card' : ''")
  router-link(v-if="wide" @click="openItem()" :to="getURL()")
    q-item(dense style="position: relative; border-bottom: 1px solid rgba(0, 0, 0, 0.12); height: 100px;")
      q-item-section(side)
        .flex.flex-center(style="background: #E3E3E3; height: 80px; width: 80px;")
          s3-image(:ratio="1" :src="selectedProduct.img" img-style="width: 80px; height: 80px;")
      q-item-section
        .row.justify-between.items-center.no-wrap.full-height
          .full-width
            .product-category(v-if="item.categories && item.categories[0]") {{item.categories[0].name}}
            .product-name(v-if="type === 'MAIN_PRODUCT'") {{ item.name.name }}
            .product-name(v-else) {{ selectedProduct.product_name }}
            .row(:class="item.products && item.products.length > 0 ? 'justify-between' : 'justify-end'")
              .number-of-tastes(v-if="type === 'MAIN_PRODUCT'") {{ item.products.length }} {{ translate('products.nrOfTaste') }}
              .lt-sm.row(style="font-size: 1em; font-weight: 400")
                div.dark-color.text-500(v-if="!isClothing") {{ type === 'MAIN_PRODUCT' ? translate('products.possibly') : '' }}
                div.q-pl-sm.dark-color.text-500.text-no-wrap {{ prettifyCurrency(selectedProduct.prices.gross_price) }}
          .gt-sm.column.items-end.justify-end(style="font-size: 1em; font-weight: 400; height: 100%;")
            div.dark-color.text-500(v-if="!isClothing") {{ type === 'MAIN_PRODUCT' ? translate('products.possibly') : '' }}
            div.q-pl-sm.dark-color.text-500.text-no-wrap {{ prettifyCurrency(selectedProduct.prices.gross_price) }}

  div(v-if="!wide")
    .column.justify-center.items-center.content-start(:style="resizeProductListItem" style="position: relative; min-height: 30px !important;")
      q-skeleton(type="QCard" v-if="!item" style="min-height: 330px !important; min-width: 100%" :style="$q.screen.lt.md ? 'padding-bottom: 16px;' : 'flex: 1;'")
      q-card.product-list-item.full-width(flat style="border-radius: 0;")
        discount-badge(:bg-color="selectedProduct.discount.bgColor" :color="selectedProduct.discount.color" :name="selectedProduct.discount.name" v-if="selectedProduct.hasDiscount")
        div
          .row.items-center.justify-between.absolute-top(style="z-index: 1;").gt-sm
            div(style="height: 40px;")
            .row.q-ma-sm.justify-center
              template(v-if="type !== 'MAIN_PRODUCT'")
                .cursor-pointer(@click="addToWishList" v-if="!item.wishListAdded")
                  svg(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='20' height='19' fill='#484A4C')
                    path(fill='none' d='M0 0H24V24H0z')
                    path(d='M12.001 4.529c2.349-2.109 5.979-2.039 8.242.228 2.262 2.268 2.34 5.88.236 8.236l-8.48 8.492-8.478-8.492c-2.104-2.356-2.025-5.974.236-8.236 2.265-2.264 5.888-2.34 8.244-.228zm6.826 1.641c-1.5-1.502-3.92-1.563-5.49-.153l-1.335 1.198-1.336-1.197c-1.575-1.412-3.99-1.35-5.494.154-1.49 1.49-1.565 3.875-.192 5.451L12 18.654l7.02-7.03c1.374-1.577 1.299-3.959-.193-5.454z')
                  q-tooltip {{ translate('product.addToWishlist') }}
                q-icon.q-mr-sm.cursor-pointer(name="fas fa-heart" size="20px" color="pink" v-else @click="removeFromWishList")
                  q-tooltip {{ translate('product.removeFromWishList') }}
          router-link.flex.flex-center.cursor-pointer(@click="openItem" :to="getURL()")
            s3-image(:src="type === 'MAIN_PRODUCT' ? item.img : selectedProduct.img" )
          q-btn.gt-sm.bg-white.absolute.fast-purchase.flex.flex-center(@click="doFastPurchase()" style="border-radius: 5px" no-caps flat)
            .q-mr-xs.flex.flex-center
              svg(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24' fill="#6d7075")
                path(fill='none' d='M0 0h24v24H0z')
                path(d='M7 8V6a5 5 0 1 1 10 0v2h3a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h3zm0 2H5v10h14V10h-2v2h-2v-2H9v2H7v-2zm2-2h6V6a3 3 0 0 0-6 0v2z')
            .workSans {{translate('product.buyNow')}}
      router-link.productListItemName(@click="openItem" :to="getURL()" v-if="type === 'MAIN_PRODUCT'") {{ item.name.name }}
      router-link.productListItemName(@click="openItem" :to="getURL()" v-else) {{ selectedProduct.product_name }}
      router-link.row.full-width.flex.items-center.q-py-sm(@click="openItem" :to="getURL()")
        q-rating.q-mr-xs(v-model="selectedProduct.overallRating" :size="$q.screen.gt.sm ? '16px' : '1em'" style="color: #0370F7" readonly)
        .flex.flex-center(style="color: rgb(3, 112, 247); font-size: 13px;") ({{ selectedProduct.ratingCount }})
      .full-width.row.no-wrap(v-if="selectedProduct.nrOfAllowedGifts > 0 &&selectedProduct.gifts && selectedProduct.gifts.length > 0" @click="openItem").flex.items-center
        svg(xmlns='http://www.w3.org/2000/svg' style="min-width: 13px" :height="$q.screen.lt.sm ? 18 : 24" viewBox='0 0 24 24' :width="$q.screen.lt.sm ? 18 : 24" fill="#e02838")
          path(fill='none' d='M0 0h24v24H0z')
          path(d='M15 2a4 4 0 0 1 3.464 6.001L23 8v2h-2v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V10H1V8l4.536.001A4 4 0 0 1 12 3.355 3.983 3.983 0 0 1 15 2zm-4 8H5v9h6v-9zm8 0h-6v9h6v-9zM9 4a2 2 0 0 0-.15 3.995L9 8h2V6a2 2 0 0 0-1.697-1.977l-.154-.018L9 4zm6 0a2 2 0 0 0-1.995 1.85L13 6v2h2a2 2 0 0 0 1.995-1.85L17 6a2 2 0 0 0-2-2z')
        .giftTitle {{ translate('product.chooseOneFreeShort') }}
      router-link.col-12.column.full-width(@click="openItem" :to="getURL()")
        .row.row.items-center
          .q-mr-xs.text-lowercase(style="font-size: 18px; font-weight: 500; color: #4E4F53" v-if="type === 'MAIN_PRODUCT' && item.products.length > 0 && !isClothing") {{ translate('products.possibly')}}
          .row
            .price-size(:class="selectedProduct.hasDiscount ? 'text-strike' : ''") {{ prettifyCurrency(selectedProduct.prices.gross_price, 0) }}
            .price-size.q-ml-md.text-red(v-if="selectedProduct.hasDiscount") {{ prettifyCurrency(selectedProduct.discount.prices.gross_price, 0) }}
        .quantity-size {{ selectedProduct.hasDiscount ? selectedProduct.discount.unitPrice.name : selectedProduct.unitPrice }}
  fast-purchase-modal(@open-item="openItem" @do-purchase="doFastPurchase" v-model:visible="showFastPurchaseEditor" :products="item.products" :item="item" v-model:selected-product="selectedProduct" v-if="type === 'MAIN_PRODUCT'")
</template>

<style lang="sass" scoped>
.search-result-item
  cursor: pointer
  width: 100%
  .q-item--dense
    padding: 10px 16px
  .product-name
    color: #4D4F53
    font-size: 14px
    font-weight: 500
    line-height: 1.1
  .product-category
    font-size: 10px
    color: #4D4F53
  .number-of-tastes
    font-size: 14px
    color: #4D4F53
.product-list-item
  position: relative
  background: #dfe0e3
.fast-purchase
  font-family: 'Work Sans', sans-serif
  bottom: 5%
  right: 5%
  min-width: 150px
  color: #4d4f53
.price-size
  font-size: 18px
  font-weight: 500
  color: #4E4F53
  @media screen and (max-width: 599px)
    padding-right: 6px
.smallerQuantitySize
  @media screen and (max-width: 599px)
    font-size: 10px !important
  @media screen and (min-width: 600px)
    font-size: 14px !important
.quantity-size
  @media screen and (max-width: 599px)
    font-size: 12px
  @media screen and (min-width: 600px)
    font-size: 16px
  font-weight: 400
  color: #B5B5B7
  line-height: 1
.product-card
  border-radius: 4px
  z-index: 0
  border: 1px transparent solid
  transition: all 0.33s ease-in-out
  &:hover
    transform: translateY(-3px)
    background-color: white
.giftTitle
  font-size: 16px
  color: #7E7E80
  margin-left: 3px
  @media screen and (max-width: 600px)
    font-size: 12px
.productListItemName
  width: 100%
  margin-top: 8px
  cursor: pointer
  display: flex
  align-items: center
  text-align: left
  color: #4E4F51
  font-size: 18px
  line-height: 1.1
  flex: 1 1 auto
  font-weight: 400
</style>
