<script>
export default {
  name: 'ProductSkeleton',
  props: ['imgSize', 'textHeight'],
  setup (props) {

  }
}

</script>

<template lang="pug">
.skeleton-wrapper
  q-skeleton.skeleton-img(:size="imgSize" animation="none" square)
  .skeleton-text-container
    q-skeleton.skeleton-text(:height="textHeight" type="text" square width="100%" animation="none")
    q-skeleton.skeleton-text(:height="textHeight" type="text" square width="100%" animation="none")
    q-skeleton.skeleton-text(:height="textHeight" type="text" square width="50%" animation="none")
</template>

<style scoped lang="sass">
.skeleton-wrapper
  display: flex
  flex-direction: row
  width: 100%
  padding-bottom: 16px
  .skeleton-img, .skeleton-text
    background: #ECECEE
  .skeleton-text-container
    display: flex
    flex-direction: column
    flex: 1
    padding-left: 16px
    padding-top: 6px
</style>
