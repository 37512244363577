import { useLayout } from 'src/composables/useLayout'
import { server } from 'boot/meteor'
const { translate } = useLayout()

const validateEmail = (val) => {
  if (!val || val.length < 1) { return translate('validations.email') }
  if (!/^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/.test(val)) { return translate('validations.email') }
}
const validatePassword = (val) => {
  if (!val || val.length < 1) { return translate('validations.password') }
  if (val.length < 6) { return translate('validations.min6Char') }
}
const required = (val) => {
  if (!val || val.length < 1) { return translate('validations.required') }
}
const otpToken = (val) => {
  if (!val || val.length < 6) { return 'The token has to be 6 characters long.' }
  if (!/^\d+$/.test(val)) { return 'Please enter a valid number.' }
}
const number = (val) => {
  if (val && !/^[0-9]+(\.[0-9]{1,2})?$/.test(val)) { return 'It has to be a valid number.' }
}
const numberMinus = (val) => {
  if (!/^-?\d*\.?\d+$/.test(val)) { return 'It has to be a valid number.' }
}
const validateUniqueEmail = async (val) => {
  if (!val || val.length < 1) { return translate('validations.email') }
  if (!/^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/.test(val)) { return translate('validations.email') }
  const isUnique = await server.call('validateEmail', val)
  if (!isUnique) {
    return translate('validations.emailNotUnique')
  }
}

export function useValidations () {
  return {
    validateEmail,
    validatePassword,
    required,
    otpToken,
    number,
    numberMinus,
    validateUniqueEmail
  }
}
