<script setup>
import { computed, ref, defineProps, defineEmits } from 'vue'
import { useLayout } from 'src/composables/useLayout'
import S3Image from 'components/general/S3Image'
import { useFilters } from 'src/composables/useFilters'
import { useCart } from 'src/composables/useCart'

const props = defineProps(['visible', 'selectedProduct', 'products'])
const emit = defineEmits(['update:visible', 'update:selectedProduct', 'doPurchase', 'openItem'])
const { translate, barStyle, thumbStyle } = useLayout()
const { prettifyNumber, prettifyCurrency } = useFilters()
const { shoppingCart } = useCart()
const quantityToBuy = ref(1)

const computedVisible = computed({
  get () {
    return props.visible
  },
  set (val) {
    emit('update:visible', val)
  }
})

const computedProduct = computed({
  get () {
    return props.selectedProduct
  },
  set (val) {
    emit('update:selectedProduct', val)
  }
})

const uniqueTasteProducts = computed(() => {
  const res = []
  if (props.products) {
    const currentPackaging = props.products.find(e => e.product_code === computedProduct.value.product_code).packaging
    props.products.forEach(product => {
      const productsWithSameTaste = props.products.filter(e => e.taste === product.taste)
      if (productsWithSameTaste.length > 1 && product.packaging !== currentPackaging && productsWithSameTaste.some(e => e.packaging === currentPackaging)) {
        return
      }
      if (!res.some(e2 => e2.taste === product.taste)) {
        res.push(product)
      }
    })
    return res.sort((a, b) => (isNaN(a.tasteOrder) ? 9999 : a.tasteOrder) > (isNaN(b.tasteOrder) ? 9999 : b.tasteOrder) ? 1 : -1)
  }
  return res
})

const uniquePackagingProducts = computed(() => {
  const res = []
  if (props.products) {
    props.products.forEach(product => {
      if (!res.some(e2 => e2.packaging === product.packaging) && (!computedProduct.value || product.taste === computedProduct.value.taste)) {
        res.push(product)
      }
    })
  }
  return res.sort((a, b) => a.packagingOrder > b.packagingOrder ? 1 : -1)
})
</script>

<template lang="pug">
q-dialog.fast-purchase-modal(:maximized="$q.screen.width <= 600" v-model="computedVisible")
  .fast-purchase-wrapper(v-if="selectedProduct")
    .fast-purchase-header
      | {{translate('product.fastPurchase')}}
      svg.close-btn(@click="computedVisible = false" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='28' height='28' fill="#222222")
        path(fill='none' d='M0 0h24v24H0z')
        path(d='M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z')
    q-separator(size="2px" style="background: #F2F2F2")
    .fast-purchase-body
      .img-wrapper
        s3-image(:ratio="1" :src="selectedProduct.img" img-style="width: 200px; height: 200px;")
      .product-wrapper
        .product-description
          .product-title {{ selectedProduct.product_name }}
          .rating-wrapper
            q-rating.product-rating(v-model="selectedProduct.overallRating" readonly)
              q-tooltip(v-if="selectedProduct.overallRating") {{ prettifyNumber(selectedProduct.overallRating, 2) }}
            .rating-count ({{ selectedProduct.ratingCount }})
          .product-taste {{translate('product.taste')}}:
          q-btn-dropdown.drop-down-btn(:label="selectedProduct.taste" no-caps flat dropdown-icon="arrow_drop_down")
            q-scroll-area(:bar-style="barStyle" :thumb-style="thumbStyle" :style="`height: ${uniqueTasteProducts.length < 6 ? uniqueTasteProducts.length * 50 : 300}px;`").full-width
              q-list
                q-item.taste-selector(@click="computedProduct = tasteProduct" v-for="tasteProduct in uniqueTasteProducts" :key="tasteProduct._id" clickable v-close-popup) {{tasteProduct.taste}}
          .product-package-sizes {{translate('products.packageSizes')}}:
          q-btn-dropdown.drop-down-btn(:label="computedProduct.packaging" no-caps flat dropdown-icon="arrow_drop_down")
            q-scroll-area(:bar-style="barStyle" :thumb-style="thumbStyle" :style="`height: ${uniquePackagingProducts.length < 6 ? uniquePackagingProducts.length * 50 : 300}px`").full-width
              q-list
                q-item.quantity-selector(@click="computedProduct = packagingProduct" v-for="(packagingProduct, index) in uniquePackagingProducts" :key="index" clickable v-close-popup) {{packagingProduct.packaging}}
          .product-quantity {{translate('products.quantity')}}:
          .quantity-to-purchase
            .flex
              q-btn.decrement(@click="quantityToBuy > 1 ? quantityToBuy-- : null" flat dense)
                svg(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24' :fill="quantityToBuy > 1 ? '#222222' : '#7E7E7E'")
                  path(fill='none' d='M0 0h24v24H0z')
                  path(d='M5 11h14v2H5z')
              .quantity-count {{ quantityToBuy }}
              q-btn.increment(@click="quantityToBuy < selectedProduct.stock ? quantityToBuy++ : null" flat dense)
                svg(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24' :fill="quantityToBuy < selectedProduct.stock ? '#222222' : '#7E7E7E'")
                  path(fill='none' d='M0 0h24v24H0z')
                  path(d='M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z')
            .product-price {{ prettifyCurrency(selectedProduct.prices.gross_price * quantityToBuy, 0) }}
          q-btn.to-basket(@click="$emit('doPurchase', quantityToBuy)" no-caps flat)
            svg(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24' fill="white")
              path(fill='none' d='M0 0h24v24H0z')
              path(d='M7 8V6a5 5 0 1 1 10 0v2h3a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h3zm0 2H5v10h14V10h-2v2h-2v-2H9v2H7v-2zm2-2h6V6a3 3 0 0 0-6 0v2z')
            | {{translate('products.toBasket')}}
          q-btn.additional-info(@click="$emit('openItem')" :label="translate('products.additionalInformation')" no-caps flat)
</template>

<style lang="sass">
.fast-purchase-wrapper
  min-height: 650px
  width: 700px
  background: white
  border-radius: 12px !important
  padding: 0 24px
  .fast-purchase-header
    padding: 20px 10px
    color: #4D4F53
    font-size: 22px
    font-weight: 600
    display: flex
    flex-direction: row
    align-items: center
    position: relative
    .close-btn
      position: absolute
      right: 0
      top: 16px
      cursor: pointer
  .fast-purchase-body
    padding: 20px 0
    display: flex
    flex-direction: row
    @media screen and (max-width: 599px)
      flex-direction: column !important
    .img-wrapper
      display: flex
      align-items: center
      justify-content: center
      background: #E3E3E3
      height: 200px
      width: 200px
      @media screen and (max-width: 599px)
        align-self: center
    .product-wrapper
      padding: 10px 0 0 20px
      flex: 1
      @media screen and (max-width: 599px)
        padding: 10px 0 0 0 !important
      .product-description
        .product-title
          font-size: 24px
          color: #4D4F53
          font-weight: 600
          line-height: 1.2
          padding-bottom: 10px
        .rating-wrapper
          display: flex
          flex-direction: row
          align-items: center
          padding-bottom: 16px
          .product-rating
            margin-right: 4px
            color: #0370F7
          .rating-count
            color: #4D4F53
        .product-taste, .product-package-sizes, .product-quantity
          color: #4D4F53
          font-size: 15px
          font-weight: 500
          padding-bottom: 6px
        .drop-down-btn
          border-radius: 8px
          color: #4D4F53
          font-size: 16px
          height: 50px
          margin-bottom: 16px
          background: #ECECEE
          width: 100%
          box-shadow: 0 2px 2px 0 rgba(223,224,227,1)
          -webkit-box-shadow: 0 2px 2px 0 rgba(223,224,227,1)
          -moz-box-shadow: 0 2px 2px 0 rgba(223,224,227,1)
          -o-box-shadow: 0 2px 2px 0 rgba(223,224,227,1)
          i
            font-size: 20px !important
            color: black !important
          .q-btn__content
            justify-content: space-between
        .quantity-to-purchase
          display: flex
          flex-direction: row
          align-items: center
          justify-content: space-between
          padding-bottom: 20px
          .quantity-count
            min-width: 60px
            display: flex
            align-items: center
            justify-content: center
            font-size: 20px
            color: #4D4F53
          .increment, .decrement
            height: 50px
            width: 50px
            border-radius: 8px
            cursor: pointer
            background: #ECECEE
            box-shadow: 0 2px 2px 0 rgba(223,224,227,1)
            -webkit-box-shadow: 0 2px 2px 0 rgba(223,224,227,1)
            -moz-box-shadow: 0 2px 2px 0 rgba(223,224,227,1)
            -o-box-shadow: 0 2px 2px 0 rgba(223,224,227,1)
          .product-price
            font-size: 20px
            color: #4D4F53
            font-weight: 600
        .to-basket, .additional-info
          width: 100%
          border-radius: 8px
          font-size: 18px
          height: 60px
          font-weight: 600
        .to-basket
          background: #0470F7
          color: white
          margin-bottom: 16px
          svg
            margin-right: 10px
        .additional-info
          background: #ECECEE
          color: #4D4F53
          box-shadow: 0 2px 2px 0 rgba(223,224,227,1)
          -webkit-box-shadow: 0 2px 2px 0 rgba(223,224,227,1)
          -moz-box-shadow: 0 2px 2px 0 rgba(223,224,227,1)
          -o-box-shadow: 0 2px 2px 0 rgba(223,224,227,1)
.taste-selector, .quantity-selector
  height: 50px
  align-items: center
</style>
