<script>
import S3Image from '../general/S3Image'
import { useLayout } from 'src/composables/useLayout'
import { useCart } from 'src/composables/useCart'
import { computed, ref, watch } from 'vue'
import PopupItem from 'components/product/PopupItem'
import { useQuasar, throttle } from 'quasar'
import { useFilters } from 'src/composables/useFilters'
import { useRouter } from 'vue-router'
import { Carousel, Slide, Navigation, Pagination } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
export default {
  name: 'CartPopup',
  components: { S3Image, PopupItem, Carousel, Slide, Navigation, Pagination },
  setup () {
    const { translate, thumbStyle, barStyle, similarProducts } = useLayout()
    const { shoppingCartCount, showCartPopup, newCartItem, onlyCartItemsAmount } = useCart()
    const $q = useQuasar()
    const { prettifyCurrency } = useFilters()
    const router = useRouter()
    watch(showCartPopup, () => {
      if (process.env.NODE_ENV === 'production') {
        window.fbq('track', 'PageView')
        window.fbq('track', 'cartPopup')
        window.fbq('AddToCart', { content_name: 'cartPopup', value: true })
      }
    })
    const similarProductSlide = ref(0)
    const mouseDrag = ref(false)
    const increment = throttle(function incSlide () {
      similarProductSlide.value += 1
    }, 1500)
    const decrement = throttle(function decSlide () {
      similarProductSlide.value -= 1
    }, 1500)
    const resizeGrid = computed(() => {
      if ($q.screen.gt.xs) {
        return 'grid-template-columns: repeat(4,1fr); grid-gap: 1rem;'
      }
      if ($q.screen.lt.sm) {
        return 'grid-template-columns: repeat(2,1fr); grid-gap: 0.5rem;'
      }
      return ''
    })
    const cartHeight = computed(() => {
      let res = 0
      if (newCartItem.value) {
        const itemSize = $q.screen.gt.sm ? 120 : 120
        res += itemSize
        res += newCartItem.value.gifts.length * itemSize
        if (res > 240) {
          res = 240
        }
      }
      return res
    })
    // TODO
    const openItem = (similarItem) => {
      switch (similarItem.searchType) {
        case 'PRODUCT': router.push(`/termek/${similarItem.url}`); break
        case 'MAIN_PRODUCT: ': router.push(`/fo-termek/${similarItem.url}`); break
      }
    }
    return {
      mouseDrag,
      increment,
      decrement,
      openItem,
      translate,
      thumbStyle,
      barStyle,
      similarProductSlide,
      shoppingCartCount,
      showCartPopup,
      similarProducts,
      resizeGrid,
      newCartItem,
      cartHeight,
      prettifyCurrency,
      onlyCartItemsAmount
    }
  }
}
</script>

<template lang="pug">
q-dialog(v-model="showCartPopup" :maximized="$q.screen.lt.md" transition-show="fade" transition-hide="fade" )
  .full-width.flex.flex-center
    div.flex.justify-center(:style="`width: ${$q.screen.lt.sm ? '95%' : '100%'}`")
      .bg-white(style="width: 100% !important; border-radius: 10px; position: relative;")
        .row.items-center.relative-position.full-width(style="padding: 26px 32px;")
          .row.flex.items-center
            svg(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='30' height='30' fill="#0471F7")
              path(fill='none' d='M0 0h24v24H0z')
              path(d='M4 6.414L.757 3.172l1.415-1.415L5.414 5h15.242a1 1 0 0 1 .958 1.287l-2.4 8a1 1 0 0 1-.958.713H6v2h11v2H5a1 1 0 0 1-1-1V6.414zM6 7v6h11.512l1.8-6H6zm-.5 16a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm12 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z')
            .mainTitle {{ translate('cart.addedToCart') }}
            .cursor-pointer.gt-xs(style="position: absolute; top: 10px; right: 10px;" @click="showCartPopup = false")
              svg(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='30' height='30' fill="#242424")
                path(fill='none' d='M0 0h24v24H0z')
                path(d='M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z')
              q-tooltip {{ translate('cart.closePopup') }}
            .row.cursor-pointer.absolute-right.items-center.q-mr-sm.lt-sm(@click="showCartPopup = false" style="padding-right: 6px;")
              q-tooltip {{ translate('cart.closePopup') }}
              svg(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='30' height='30' fill="#BEBEBE")
                path(fill='none' d='M0 0h24v24H0z')
                path(d='M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z')
        .separator
        .scrollAreaContainer(:style="`height: ${cartHeight}px;`")
          q-scroll-area.full-width.scroll-area(:showCartPopup="true" :bar-style="barStyle" :thumb-style="thumbStyle" :style="`height: ${cartHeight}px;`" :content-style="{top: '0 !important'}" :content-active-style="{top: '0 !important'}")
            popup-item(:cart-item="newCartItem" :hide-separator="true")
        .gradientLine
          .row.justify-center
            .gradientTitle {{ `${translate('cart.cartContent')} (${shoppingCartCount} ${translate('cart.product')}):` }}
            .gradientPrice.q-ml-sm  {{ prettifyCurrency(onlyCartItemsAmount, 0) }}
        .full-width(style="padding: 20px 15px 10px 15px;")
          .row.full-width
            q-item.q-mr-sm.q-pr-md.q-py-md.flex.flex-center.text-white.text-uppercase(:class="$q.screen.lt.sm ? 'full-width q-mb-md' : 'flex-1'" clickable @click="showCartPopup = false; $router.push('/kosar');" :style="$q.screen.lt.md ? 'font-size: 16px' : 'font-size: 20px'" style="font-weight: 600; background: #0370F7;  border-radius: 8px;")
              | {{ translate('cart.shop') }}
            q-item.q-ml-sm.q-pl-md.q-py-md.flex.flex-center.dark-color.text-uppercase(:class="$q.screen.lt.sm ? 'full-width' : 'q-mr-sm flex-1'" clickable @click="showCartPopup = false" :style="$q.screen.lt.md ? 'font-size: 16px' : 'font-size: 20px'" style="font-weight: 600; background: #F2F2F2; border-radius: 8px;")
              | {{ translate('cart.continueShopping') }}
          .row.justify-center.full-width
            .related-products-title {{translate('products.relatedProducts')}}
            .full-width.relative-position
              carousel.similar-products-carousel(v-model="similarProductSlide" :settings="{ itemsToShow: !$q.screen.lt.sm ? 4 : 2, itemsToScroll: !$q.screen.lt.sm ? 4 : 2, wrapAround: true, snapAlign: 'start', autoplay: false }")
                slide.full-height.row.items-baseline.cursor-pointer(v-for="(product, index) in similarProducts" :key="index" @mousedown="mouseDrag = false" @mousemove="mouseDrag = true" @click="!mouseDrag ? openItem(product) : ''")
                  .q-mx-sm.row.justify-center
                    .flex.flex-center(style="width: 150px; height: 150px;")
                      s3-image(style="background: #DFE0E3;" img-style="height: 150px; width: 150px;" :ratio="1" :src="product.img[0]")
                    .column.items-center
                      .dark-color.q-pt-xs.text-600(style="line-height: 16px") {{product.product_name}}
                      q-rating.q-pt-xs(v-model="product.overallRating" readonly color="blue-grey-13" color-selected="blue-grey-14" :size="$q.screen.lt.sm ? '16px' : '20px'")
                      .dark-color.q-pt-xs(:class="product.hasDiscount ? 'text-strike' : ''" :style="`font-size: ${$q.screen.lt.sm ? '16px' : '18px'}`") {{ prettifyCurrency(product.prices.gross_price, 0) }}
                      .text-red(v-if="product.hasDiscount" style="font-size: 20px") {{ prettifyCurrency(product.discount.prices.gross_price, 0) }}
</template>

<style lang="sass">
.similar-products-carousel
  display: flex
  flex-direction: row
  .carousel__slide
    align-items: baseline
  .q-rating__icon
    text-shadow: none
  .carousel__viewport, .carousel__track
    height: 100%
  .carousel__next
    right: 10px
  .carousel__prev
    left: 10px
  .carousel__prev, .carousel__next
    .carousel__icon
      color: white
    cursor: pointer
    background: #4D4F53
    border-radius: 100px
    width: 40px
    height: 40px
    display: flex
    justify-content: center
    align-items: center
    z-index: 1
    position: absolute
    top: 50%
    transition: background-color 0.33s ease-in-out
    &:hover
      background: darkgray
.related-products-title
  display: flex
  color: #4D4F53
  font-size: 20px
  flex-direction: row
  font-weight: 400
  width: 100%
  margin: 16px 0
.related-products-title:before,
.related-products-title:after
  content: ""
  flex: 1
  border-bottom: 1px solid #EAEAEA
  margin: auto
.related-products-title:before
  margin-right: 2%
.related-products-title:after
  margin-left: 2%
.scroll-area > * > .absolute > :last-child > div > div > .popup-item
  padding-bottom: 8px !important
.scroll-area > * > .absolute > div:last-child > .full-width > .full-width:last-child > div > hr
  display: none
.gradientLine
  background: rgba(241,34,42,1)
  background: -moz-linear-gradient(90deg, rgba(241,34,42,1) 0%, rgba(16,108,235,1) 100%)
  background: -webkit-linear-gradient(90deg, rgba(241,34,42,1) 0%, rgba(16,108,235,1) 100%)
  background: linear-gradient(90deg, rgba(241,34,42,1) 0%, rgba(16,108,235,1) 100%)
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#106ceb",endColorstr="#f1222a",GradientType=1)
  padding: 10px 10px
  display: flex
  justify-content: center
  align-items: center
  width: 100%
.separator
  background: #F2F2F2
  height: 2px
  margin: 0 15px
.mainTitle
  font-size: 22px
  color: #4D4F53
  font-weight: 600
  margin-left: 10px
  @media screen and (max-width: 1000px)
    font-size: 20px
.gradientTitle, .gradientPrice
  font-size: 18px
  @media screen and (max-width: 1023px)
    font-size: 14px !important
  color: #F7F6F9
.gradientTitle
  font-weight: 400
.gradientPrice
  font-weight: 600
.review-rating > .q-icon
  text-shadow: none !important
.priceTitle
  color: #4D4F53
  font-size: 14px
.toBasketBtn
  width: 100px
  margin-top: 5px
  padding: 0 20px 0 20px
  border-radius: 8px
  box-shadow: 0 3px 2px 0 rgba(223,224,227,1)
  -webkit-box-shadow: 0 3px 2px 0 rgba(223,224,227,1)
  -moz-box-shadow: 0 3px 2px 0 rgba(223,224,227,1)
  display: flex
  align-items: center
  justify-content: center
  font-weight: 600
  font-size: 16px
  color: #4d4f53
  background: #ECECEE
  height: 30px
  cursor: pointer
.similarProduct
  transition: all 0.33s ease-in-out
  &:hover
    transform: translateY(-3px)
    background-color: white
.carousel
  height: 100%
  width: 100% !important
.slideWidth
  min-width: 25% !important
  @media screen and (max-width: 1020px)
    min-width: 50% !important
  @media screen and (max-width: 599px)
    min-width: 50% !important
.scrollAreaContainer
  width: 100%
  padding: 10px 18px 10px 18px
  margin-bottom: 10px
  @media screen and (max-width: 1023px)
    padding: 0 !important
    margin-bottom: 0px !important
.q-dialog__inner--minimized > div
 max-width: 750px !important
</style>
