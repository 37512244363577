<script>
import { useLayout } from 'src/composables/useLayout'
import { computed, ref, watch } from 'vue'
import { useAuth } from 'src/composables/useAuth'
import CountrySelector from 'components/general/CountrySelector'
import MobileMenuItem from 'components/layout/MobileMenuItem'
import { useProductsPage } from 'src/composables/useProductsPage'
export default {
  name: 'MobileMenu',
  components: { MobileMenuItem, CountrySelector },
  setup () {
    const { translate, showDrawer, showMobileSearchResults, categories, productFamilies, extraMenus } = useLayout()
    const { showLogin } = useAuth()
    const { category, productFamily } = useProductsPage()
    const activeTab = ref('MENU')
    const productsHeight = computed(() => {
      return categories.value.length * 48
    })
    const productFamilyHeight = computed(() => {
      return productFamilies.value.length * 48
    })
    watch(showDrawer, (val) => {
      if (val) {
        showMobileSearchResults.value = false
        showLogin.value = false
        activeTab.value = 'MENU'
      }
    })

    const innerHeight = window.innerHeight
    return {
      innerHeight,
      category,
      productFamily,
      translate,
      showDrawer,
      productsHeight,
      productFamilyHeight,
      categories,
      productFamilies,
      extraMenus,
      activeTab
    }
  }
}
</script>

<template lang="pug">
q-dialog(v-model="showDrawer" position="left" maximized)
  .mobileMenuContainer
    .row.justify-between.relative-position(style="height: 50px").items-center.q-px-md
      .row(@click="activeTab = 'MENU'")
        template(v-if="activeTab !== 'MENU'")
          svg(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24' fill="#48494E")
            path(fill='none' d='M0 0h24v24H0z')
            path(d='M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z')
          .flex.items-center.label {{ translate('auth.back') }}
      .cursor-pointer.flex.flex-center(v-close-popup)
        svg(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24' fill="#48494E")
          path(fill='none' d='M0 0h24v24H0z')
          path(d='M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z')
    q-tab-panels(v-model="activeTab" animated)
      q-tab-panel(name="PRODUCT_FAMILIES").q-pa-none
        div
          mobile-menu-item(v-for="productFamily in productFamilies" :key="productFamily.name" @close="showDrawer = false" :label="productFamily.name" :path="`/termekek/${productFamily.url}`")
      q-tab-panel(name="CATEGORIES").q-pa-none
        div
          mobile-menu-item(v-for="category in categories" :key="category._id" @close="showDrawer = false" :label="category.name" :path="`/termekek/${category.url}`")
      q-tab-panel(name="MENU").q-pa-none
        div(:style="`height: ${innerHeight - 200}px`")
          mobile-menu-item(@close="showDrawer = false" :label="translate('home.home')" path="/")
          mobile-menu-item(:is-active="$route.name === 'Termékek' && category" @close="showDrawer = false" :label="translate('home.products')" @clicked="activeTab = 'CATEGORIES'")
          mobile-menu-item(:is-active="$route.name === 'Termékek' && productFamily" @close="showDrawer = false" :label="translate('home.productFamilies')" @clicked="activeTab = 'PRODUCT_FAMILIES'")
          mobile-menu-item(@close="showDrawer = false" :label="translate('home.blog')" path="/blog")
          mobile-menu-item(@close="showDrawer = false" :label="translate('home.expertAnswers')" path="/szakertoink-valaszai")
          div(v-for="menu in extraMenus")
            mobile-menu-item(@close="showDrawer = false" :label="menu.menuName" :path="`/oldal/${menu.slug}`")
        .menuItemsContainer(style="background-color: #ECECEE; z-index: 1; height: 150px;")
          .q-px-md
            .menuItem(@click="$route.path === '/vevoszolgalat' ? showDrawer = !showDrawer : $router.push('/vevoszolgalat')" style="font-size: 1em;")
              svg(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill="#AAABAD" width='24' height='24')
                path(fill='none' d='M0 0h24v24H0z')
                path(d='M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm17 4.238l-7.928 7.1L4 7.216V19h16V7.238zM4.511 5l7.55 6.662L19.502 5H4.511z')
              .label {{translate('home.companyContact')}}
          .q-py-md
            country-selector(:dense="true").full-width
</template>

<style scoped lang="sass">
.mobileMenuContainer
  background: white
  width: 85vw
  max-width: 320px
  height: 100vh
  .menuItemsContainer
    padding: 25px
    .menuItem
      display: flex
      flex-direction: row
      align-items: center
      .label
        margin-left: 15px
        font-size: 18px
        color: #47484C
        font-weight: 500
</style>
