
const routes = [
  { path: '/', name: 'Főoldal', component: () => import('../pages/Home') },
  { path: '/profil', component: () => import('../pages/Profile') },
  { path: '/vevoszolgalat', name: 'Vevőszolgálat', component: () => import('../pages/CustomerService') },
  { path: '/vevoszolgalat/siker', name: 'Vevőszolgálat Siker', component: () => import('../pages/EmailSentSuccess') },
  { path: '/sikeres-rendeles', props: true, component: () => import('../pages/OrderComplete') },
  { path: '/sikertelen-rendeles', props: true, component: () => import('../pages/OrderFailed') },
  { path: '/jelszo-vissza-allitas', component: () => import('../pages/ResetPassword') },
  { path: '/blog/:slug', props: true, component: () => import('../pages/BlogPage') },
  { path: '/blog/', component: () => import('../pages/Blogs') },
  { path: '/szakertoink-valaszai', component: () => import('../pages/CustomerQuestions') },
  { path: '/termekek/:url/', name: 'Termékek', props: true, component: () => import('../pages/Products') },
  { path: '/termek/:url/', props: true, component: () => import('../pages/Product') },
  { path: '/fo-termek/:url/', props: true, component: () => import('../pages/MainProduct') },
  { path: '/kosar', name: 'Kosár', component: () => import('../pages/Cart') },
  { path: '/ertekeles/:_id', props: true, component: () => import('../pages/Review') },
  { path: '/oldal/:slug/', props: true, component: () => import('../pages/StaticPage') },
  {
    path: '/:catchAll(.*)*',
    component: () => import('../pages/Error404.vue')
  }
]

export default routes
