import { computed, reactive, toRefs, watch } from 'vue'
import { debounce, Notify, useQuasar } from 'quasar'
import { useLayout } from 'src/composables/useLayout'
import { server } from 'boot/meteor'

const $q = useQuasar()
const { locale, translate, country } = useLayout()

const state = reactive({
  shoppingCart: [],
  fullShoppingCart: [],
  newCartItem: null,
  showCartPopup: false,
  giftCode: null,
  gifts: null,
  couponCode: null,
  coupon: null,
  couponLoaded: false,
  couponErrorMessage: null,
  delivery: 'DPD_FUTAR',
  pickUpPoints: [],
  deliveryOptions: [],
  payment: null,
  selectedPickUpPoint: null,
  showChooseGift: false,
  customerDetails: {
    differentDeliveryAddress: false,
    newUser: false,
    name: null,
    phone: null,
    email: null,
    zip: null,
    city: null,
    street: null,
    country_code: 'HU',
    taxnumber: null,
    postal_name: null,
    postal_zip: null,
    postal_street: null,
    postal_country_code: 'HU',
    company: null,
    customer_comment: null,
    termsAccepted: false,
    signUpNewsLetter: false,
    password: null
  },
  purchaseError: null,
  showTermsAcceptedError: false
})

// watch(country, () => {
//   state.customerDetails.country_code = country.value.name
// })

let updateShoppingCart = () => {
  server.call('updateShoppingCart', state.shoppingCart)
}

updateShoppingCart = debounce(updateShoppingCart, 10000)

watch(() => state.shoppingCart, () => {
  state.couponCode = null
  state.coupon = null
  state.couponLoaded = false
  state.couponErrorMessage = null
  updateShoppingCart()
}, { deep: true })

watch(() => state.coupon, val => {
  if (val && (
    (val.allowedProducts && val.allowedProducts.length > 0) ||
    (val.bannedProducts && val.bannedProducts.length > 0) ||
    (val.allowedCategories && val.allowedCategories.length > 0) ||
    (val.bannedCategories && val.bannedCategories.length > 0)
  )) {
    if (!state.couponErrorMessage) {
      const allCartProductCodes = []
      let allCartCategories = []
      state.fullShoppingCart.forEach(fullCartItem => {
        switch (fullCartItem.type) {
          case 'PRODUCT': {
            allCartProductCodes.push(fullCartItem.product.product_code)
            allCartCategories = [...allCartCategories, ...fullCartItem.product.categories.map(e => e._id)]
            if (fullCartItem.gifts && fullCartItem.gifts.length > 0) {
              fullCartItem.gifts.forEach(gift => {
                allCartProductCodes.push(gift.product.product_code)
                allCartCategories = [...allCartCategories, ...gift.product.categories.map(e => e._id)]
              })
            }
            break
          }
          case 'MAIN_PRODUCT': {
            allCartProductCodes.push(fullCartItem.product.product_code)
            allCartCategories = [...allCartCategories, ...fullCartItem.product.categories.map(e => e._id)]
            if (fullCartItem.gifts && fullCartItem.gifts.length > 0) {
              fullCartItem.gifts.forEach(gift => {
                allCartProductCodes.push(gift.product.product_code)
                allCartCategories = [...allCartCategories, ...gift.product.categories.map(e => e._id)]
              })
            }
            break
          }
          case 'GIFT': {
            allCartProductCodes.push(fullCartItem.gift.product.product_code)
            allCartCategories = [...allCartCategories, ...fullCartItem.gift.product.categories.map(e => e._id)]
            break
          }
        }
      })
      if (
        (val.bannedProducts && val.bannedProducts.length > 0 && allCartProductCodes.some(e => val.bannedProducts.includes(e))) ||
        (val.allowedProducts && val.allowedProducts.length > 0 && allCartProductCodes.some(e => !val.allowedProducts.includes(e))) ||
        (val.bannedCategories && val.bannedCategories.length > 0 && allCartCategories.some(e => val.bannedCategories.includes(e))) ||
        (val.allowedCategories && val.allowedCategories.length > 0 && allCartCategories.some(e => !val.allowedCategories.includes(e)))
      ) {
        state.couponErrorMessage = 'invalidCartContent'
      } else {
        state.couponErrorMessage = null
      }
    }
  }
}, { deep: true })

export function useCart () {
  const resetShoppingCart = () => {
    state.shoppingCart = []
    state.fullShoppingCart = []
    state.giftCode = null
    state.couponCode = null
    state.couponLoaded = false
    state.coupon = null
    state.termsAccepted = false
    updateLocalStorageWithShoppingCart()
  }

  const updateLocalStorageWithShoppingCart = () => {
    if (process.env.SERVER) { return }
    localStorage.setItem('shoppingCart', JSON.stringify(state.shoppingCart))
  }

  const updateCartItemProduct = ({ oldProduct, mainProduct, product }) => {
    state.shoppingCart = state.shoppingCart.filter(e => e.quantity > 0).map(cartItem => {
      if (cartItem.mainProduct === mainProduct._id && cartItem.product === oldProduct.product_code) {
        cartItem.product = product.product_code
        cartItem.gifts = []
        return cartItem
      }
      return cartItem
    })
    state.fullShoppingCart = state.fullShoppingCart.map(fullCartItem => {
      if ((fullCartItem.mainProduct._id === mainProduct._id) && (fullCartItem.product.product_code === oldProduct.product_code)) {
        fullCartItem.product = product
        return fullCartItem
      }
      return fullCartItem
    })
    updateLocalStorageWithShoppingCart()
  }
  const updateCartItemQuantity = ({ quantity, type, product, mainProduct }) => {
    state.shoppingCart = state.shoppingCart.filter(e => e.quantity > 0).map(cartItem => {
      if (
        (type === 'PRODUCT' && cartItem.product === product) ||
        (type === 'MAIN_PRODUCT' && cartItem.mainProduct === mainProduct && cartItem.product === product)
      ) {
        cartItem.quantity = quantity
        return cartItem
      }
      return cartItem
    })
    state.fullShoppingCart = state.fullShoppingCart.map(fullCartItem => {
      if (
        (type === 'PRODUCT' && fullCartItem.product.product_code === product) ||
        (type === 'MAIN_PRODUCT' && fullCartItem.mainProduct._id === mainProduct && fullCartItem.product.product_code === product)
      ) {
        fullCartItem.quantity = quantity
        return fullCartItem
      }
      return fullCartItem
    })
    updateLocalStorageWithShoppingCart()
  }
  const updateCartItemGifts = ({ gifts, type, product, mainProduct }) => {
    if (gifts && gifts.length > 0) {
      gifts = gifts.filter(e => e.quantity > 0)
    }
    state.shoppingCart = state.shoppingCart.filter(e => e.quantity > 0).map(cartItem => {
      if (
        (type === 'PRODUCT' && cartItem.product === product) ||
        (type === 'MAIN_PRODUCT' && cartItem.mainProduct === mainProduct && cartItem.product === product)
      ) {
        cartItem.gifts = gifts.map(e => {
          return {
            product: e.product.product_code,
            quantity: e.quantity
          }
        })
        return cartItem
      }
      return cartItem
    })
    updateLocalStorageWithShoppingCart()
    state.fullShoppingCart = state.fullShoppingCart.map(fullCartItem => {
      if (
        (type === 'PRODUCT' && fullCartItem.product.product_code === product) ||
        (type === 'MAIN_PRODUCT' && fullCartItem.mainProduct && fullCartItem.mainProduct._id === mainProduct && fullCartItem.product.product_code === product)
      ) {
        fullCartItem.gifts = gifts
        return fullCartItem
      }
      return fullCartItem
    })
    updateLocalStorageWithShoppingCart()
  }
  const addProduct = (payload) => {
    const { quantity, type, mainProduct, product, gifts } = payload
    try {
      let foundItem = false
      state.shoppingCart = state.shoppingCart.filter(e => e.quantity > 0).map(cartItem => {
        if (
          (type === 'PRODUCT' && cartItem.product === product) ||
          (type === 'MAIN_PRODUCT' && cartItem.mainProduct === mainProduct && cartItem.product === product)
        ) {
          foundItem = true
          cartItem.quantity += quantity
          if (gifts && gifts.length > 0) {
            if (cartItem.gifts && cartItem.gifts.length > 0) {
              gifts.forEach(selectedGift => {
                const selectedCartGift = cartItem.gifts.find(gift => selectedGift.product === gift.product)
                if (selectedCartGift) {
                  selectedCartGift.quantity += selectedGift.quantity
                } else {
                  cartItem.gifts.push(selectedGift)
                }
              })
            } else {
              cartItem.gifts = gifts
            }
          }
        }
        return cartItem
      })
      if (!foundItem) {
        state.shoppingCart = [payload, ...state.shoppingCart]
      }
      updateLocalStorageWithShoppingCart()
    } catch (e) {
      console.log('ASD', e)
    }
  }
  const removeProduct = ({ product, type, mainProduct }) => {
    state.shoppingCart = state.shoppingCart.filter(e => e.quantity > 0).filter(cartItem => !(
      (type === 'PRODUCT' && cartItem.product === product) ||
      (type === 'MAIN_PRODUCT' && cartItem.mainProduct === mainProduct && cartItem.product === product)
    ))
    state.fullShoppingCart = state.fullShoppingCart.filter(fullCartItem => {
      return !(
        (type === 'PRODUCT' && fullCartItem.product.product_code === product) ||
        (type === 'MAIN_PRODUCT' && fullCartItem.mainProduct && fullCartItem.mainProduct._id === mainProduct && fullCartItem.product.product_code === product)
      )
    })
    updateLocalStorageWithShoppingCart()
  }

  const shoppingCartCount = computed(() => {
    let res = 0
    state.shoppingCart.forEach(cartItem => {
      res += cartItem.quantity
      if (cartItem.gifts && cartItem.gifts.length > 0) {
        cartItem.gifts.forEach(e => {
          res += e.quantity
        })
      }
    })
    return res
  })

  const itemsCountWithGifts = computed(() => {
    let res = 0
    state.shoppingCart.forEach(cartItem => {
      res += cartItem.quantity
      if (cartItem.gifts && cartItem.gifts.length > 0) {
        cartItem.gifts.forEach(gift => {
          res += gift.quantity
        })
      }
    })
    return res
  })

  const itemsCountWithGiftsIgnoreQuantity = computed(() => {
    let res = 0
    state.shoppingCart.forEach(cartItem => {
      res += 1
      if (cartItem.gifts && cartItem.gifts.length > 0) {
        res += cartItem.gifts.length
      }
    })
    return res
  })

  const refreshFullCartItems = async () => {
    if (state.shoppingCart && state.shoppingCart.length > 0) {
      try {
        const res = await server.call('validateCartItems', {
          iso: locale.value,
          cartItems: state.shoppingCart
        })
        state.fullShoppingCart = res
        console.log('REFRESHED', res)
      } catch (e) {
        state.fullShoppingCart = []
        state.shoppingCart = []
        updateLocalStorageWithShoppingCart()
        Notify.create({ type: 'info', message: translate('cart.modifiedCartItems') })
      }
    } else {
      state.fullShoppingCart = []
    }
  }

  const loadPickUpPoints = async () => {
    if (!state.pickUpPoints || state.pickUpPoints.length === 0) {
      state.pickUpPoints = await server.call('getPickUpPoints')
    }
  }

  const loadDeliveryOptions = async () => {
    if (!state.deliveryOptions || state.deliveryOptions.length === 0) {
      state.deliveryOptions = await server.call('getDeliveryOptions', locale.value)
    }
  }

  const getGiftsPrice = (cartItem) => {
    switch (cartItem.type) {
      case 'PRODUCT': {
        if (cartItem.gifts && cartItem.gifts.length > 0) {
          return cartItem.gifts.reduce((sum, current) => sum + ((current.quantity * current.prices.gross_price) || 0), 0)
        }
        break
      }
      case 'MAIN_PRODUCT': {
        if (cartItem.product && cartItem.gifts && cartItem.gifts.length > 0) {
          return cartItem.gifts.reduce((sum, current) => sum + ((current.quantity * current.prices.gross_price) || 0), 0)
        }
        break
      }
    }
    return 0
  }
  const getGiftsOriginalPrice = (cartItem) => {
    switch (cartItem.type) {
      case 'PRODUCT': {
        if (cartItem.gifts && cartItem.gifts.length > 0) {
          return cartItem.gifts.reduce((sum, current) => sum + ((current.quantity * current.product.prices.gross_price) || 0), 0)
        }
        break
      }
      case 'MAIN_PRODUCT': {
        if (cartItem.product && cartItem.gifts && cartItem.gifts.length > 0) {
          return cartItem.gifts.reduce((sum, current) => sum + ((current.quantity * current.product.prices.gross_price) || 0), 0)
        }
        break
      }
    }
    return 0
  }
  const getGifts = async () => {
    try {
      const res = await server.call('getProductGifts', {
        price: amountWithGiftAndCoupon.value,
        iso: locale.value
      })
      state.gifts = res
    } catch (e) {
      Notify.create({ type: 'negative', message: e.reason })
    }
  }

  const paymentOptions = computed(() => [
    {
      label: 'Barion',
      value: 'BARION',
      description: translate('cart.withCard'),
      price: translate('cart.free')
    },
    {
      label: 'SimplePay',
      value: 'SIMPLE_PAY',
      description: translate('cart.withCard'),
      price: translate('cart.free')
    },
    { label: 'Utánvét', value: 'CASH_ON_DELIVERY', price: translate('cart.free') }
  ])

  const loadCoupon = async () => {
    try {
      const res = await server.call('findCoupon', { code: state.couponCode, iso: locale.value })
      if (res) {
        state.couponErrorMessage = res.errorMessage
        state.coupon = res.coupon
        console.log('COUPON', res.errorMessage)
        console.log('COUPON', state.couponErrorMessage)
      }
      state.couponLoaded = true
    } catch (e) {
      Notify.create({ type: 'negative', message: e.reason })
    }
  }

  const onlyCartItemsAmount = computed(() => {
    let res = 0
    if (state.fullShoppingCart && state.fullShoppingCart.length > 0) {
      state.fullShoppingCart.forEach(fullCartItem => {
        switch (fullCartItem.type) {
          case 'PRODUCT': res += (((fullCartItem.product.hasDiscount ? fullCartItem.product.discount.prices.gross_price : fullCartItem.product.prices.gross_price) * fullCartItem.quantity) + getGiftsPrice(fullCartItem)); break
          case 'MAIN_PRODUCT': res += (((fullCartItem.product.hasDiscount ? fullCartItem.product.discount.prices.gross_price : fullCartItem.product.prices.gross_price) * fullCartItem.quantity) + getGiftsPrice(fullCartItem)); break
        }
      })
    }
    return res
  })

  const gift = computed(() => {
    if (state.giftCode) {
      const foundGift = state.gifts.find(e => e.product.product_code === state.giftCode)
      return { type: 'GIFT', quantity: 1, gift: { prices: foundGift.prices, product: foundGift.product } }
    }
    return null
  })

  const amountWithGiftAndCoupon = computed(() => {
    let res = onlyCartItemsAmount.value
    if (gift.value && gift.value.gift && gift.value.gift.prices) {
      res += gift.value.gift.prices.gross_price
    }
    console.log('ANYAD', state.couponErrorMessage)
    if (state.coupon && !state.couponErrorMessage) {
      if (state.coupon.discountType === 'FLAT') {
        res = res - state.coupon.amount.net_price
      } else if (state.coupon.discountType === 'PERCENTAGE') {
        res = res - (res * (state.coupon.amount.net_price / 100))
      }
    }
    return res
  })

  const priceToPay = computed(() => {
    let res = amountWithGiftAndCoupon.value
    const deliveryOption = state.deliveryOptions.find(e => e.type === state.delivery)
    if (deliveryOption &&
      (!state.coupon || (state.coupon && !state.coupon.hasFreeDelivery)) &&
      res < country.value.free_delivery_limit_gross) {
      res += deliveryOption.prices.gross_price
    }
    return res
  })
  const originalAmountWithGift = computed(() => {
    let res = 0
    if (state.fullShoppingCart && state.fullShoppingCart.length > 0) {
      state.fullShoppingCart.forEach(cartItem => {
        switch (cartItem.type) {
          case 'PRODUCT': res += ((cartItem.product.prices.gross_price * cartItem.quantity) + getGiftsOriginalPrice(cartItem)); break
          case 'MAIN_PRODUCT': res += ((cartItem.product.prices.gross_price * cartItem.quantity) + getGiftsOriginalPrice(cartItem)); break
        }
      })
    }
    if (gift.value && gift.value.gift && gift.value.gift.prices) {
      res += gift.value.gift.prices.gross_price
    }
    return res
  })

  const cartHeight = computed(() => {
    let res = 0
    if (state.fullShoppingCart) {
      state.fullShoppingCart.forEach((cartItem, index) => {
        const selectedGiftsLength = cartItem.gifts.filter(e => e.quantity > 0).length
        if (cartItem.gifts && cartItem.gifts.length > 0 && !state.isCheckout) {
          if (cartItem.quantity >= selectedGiftsLength) {
            res += 70
          } else {
            res += 160
          }
        }
        res += $q.screen.gt.sm ? 150 : 160
        res += selectedGiftsLength * 116
        if (selectedGiftsLength === 0) {
          res += 120
        }
        if (res > 600) {
          res = 600
        }
        if (res > 400 && state.isCheckout && $q.screen.gt.md) {
          res = 400
        }
      })
    }
    return res
  })

  const savedPercentage = computed(() => {
    return originalAmountWithGift.value ? Math.round(((amountWithGiftAndCoupon.value / originalAmountWithGift.value) * 100) - 100) : 0
  })

  const savedTotalAmount = computed(() => {
    let res = originalAmountWithGift.value - amountWithGiftAndCoupon.value
    const deliveryOption = state.deliveryOptions.find(e => e.type === state.delivery)
    if (deliveryOption && (amountWithGiftAndCoupon.value >= country.value.free_delivery_limit_gross || (state.coupon && state.coupon.hasFreeDelivery))) {
      res += deliveryOption.prices.gross_price
    }
    return res
  })

  return {
    ...toRefs(state),
    cartHeight,
    updateCartItemQuantity,
    updateCartItemGifts,
    updateCartItemProduct,
    addProduct,
    removeProduct,
    shoppingCartCount,
    refreshFullCartItems,
    onlyCartItemsAmount,
    getGiftsPrice,
    priceToPay,
    originalAmountWithGift,
    amountWithGiftAndCoupon,
    savedTotalAmount,
    gift,
    loadCoupon,
    getGifts,
    loadPickUpPoints,
    paymentOptions,
    itemsCountWithGifts,
    resetShoppingCart,
    savedPercentage,
    itemsCountWithGiftsIgnoreQuantity,
    loadDeliveryOptions
  }
}
