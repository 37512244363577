<script>
import { useLayout } from 'src/composables/useLayout'
import { useValidations } from 'src/composables/useValidations'
import { computed } from 'vue'
import S3Image from 'components/general/S3Image'

export default {
  name: 'ProductInfoModal',
  components: { S3Image },
  props: ['product', 'visible'],
  setup (props, { emit }) {
    const { translate, thumbStyle, barStyle } = useLayout()
    const { validatePassword } = useValidations()

    const computedVisible = computed({
      get () {
        return props.visible
      },
      set (val) {
        emit('update:visible', val)
      }
    })

    return {
      translate,
      validatePassword,
      thumbStyle,
      barStyle,
      computedVisible
    }
  }
}
</script>

<template lang="pug">
q-dialog(v-model='computedVisible' :maximized="$q.screen.lt.sm")
  div(:style="$q.screen.gt.xs ? 'height: 80vh max-height: 100vh; width: 560px; max-width: 100vw; position: relative; background: #FBFFFF; border-radius: 10px;' : 'height: 100%; background: #FBFFFF;'")
    .full-width.q-pa-md
      .full-width.justify-end.row
        .cursor-pointer(@click="computedVisible = false")
          svg(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='30' height='30')
            path(fill='none' d='M0 0h24v24H0z')
            path(d='M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z')
    .full-width.flex.flex-center
      div(style="width: 230px; background: #DFE0E4; border-radius: 8px; margin-bottom: 10px; ")
        s3-image(:src="product.img[0]" img-style="width: 230px" :ratio="1/1")
      .productName {{product.product_name}}
      .productCategory {{product.categories[0].name}}
      q-scroll-area(:visible="true" :thumb-style="thumbStyle" :bar-style="barStyle" style="width: 100%; height: 400px; max-height: 45vh; margin: 0 15px; padding-top: 15px;")
        q-no-ssr
          .q-mx-md.flex.flex-center.full-width(v-html="product.nutrientTable")
        .q-mt-md.q-mx-md(v-html="product.productDescription")
</template>

<style lang="sass">
.expansion
  text-transform: capitalize
.productName
  font-size: 20px
  color: #4E4F53
  font-weight: 600
  width: 100%
  display: flex
  justify-content: center
  align-items: center
  text-align: center
  padding: 0 40px 0 40px
  line-height: 22px
.productCategory
  font-size: 18px
  color: #4E4F53
  font-weight: 500
  width: 100%
  display: flex
  justify-content: center
  align-items: center
  text-align: center
</style>
