import { date } from 'quasar'
import { useLayout } from 'src/composables/useLayout'
const digitsRE = /(\d{3})(?=\d)/g

const { country } = useLayout()

const prettifyDate = (value) => date.formatDate(value, 'YYYY-MM-DD')
const prettifyDateTime = (value) => date.formatDate(value, 'YYYY-MM-DD')
const prettifyFancyDate = (value) => date.formatDate(value, 'YYYY.MM.DD - HH:mm')
const prettifyBoolean = (value) => (value ? 'Yes' : 'No')
const prettifyList = (value) => (value || []).join(', ')
const prettifyNumber = (value, decimals) => {
  value = parseFloat(value)
  if (!isFinite(value) || (!value && value !== 0)) return ''
  decimals = decimals != null ? decimals : 0
  const stringified = Math.abs(value).toFixed(decimals)
  const _int = decimals
    ? stringified.slice(0, -1 - decimals)
    : stringified
  const i = _int.length % 3
  const head = i > 0
    ? (_int.slice(0, i) + (_int.length > 3 ? ',' : ''))
    : ''
  const _float = decimals
    ? stringified.slice(-1 - decimals)
    : ''
  const sign = value < 0 ? '-' : ''
  return sign + head +
    _int.slice(i).replace(digitsRE, '$1,') +
    _float
}
const prettifyCurrency = (value, decimals) => {
  value = parseFloat(value)
  if (!isFinite(value) || (!value && value !== 0)) return ''
  const currency = country.value && country.value.prettifiedCurrency ? country.value.prettifiedCurrency : 'Ft '
  decimals = decimals != null ? decimals : 0
  const stringified = Math.abs(value).toFixed(decimals)
  const _int = decimals
    ? stringified.slice(0, -1 - decimals)
    : stringified
  const i = _int.length % 3
  const head = i > 0
    ? (_int.slice(0, i) + (_int.length > 3 ? ' ' : ''))
    : ''
  const _float = decimals
    ? stringified.slice(-1 - decimals)
    : ''
  const sign = value < 0 ? '-' : ''
  return sign + head +
    _int.slice(i).replace(digitsRE, '$1,') +
    _float + ' ' + currency
}

export function useFilters () {
  return {
    prettifyDate,
    prettifyDateTime,
    prettifyBoolean,
    prettifyList,
    prettifyNumber,
    prettifyCurrency,
    prettifyFancyDate
  }
}
