const SimpleDDP = require('simpleddp') // nodejs
const simpleDDPLogin = require('simpleddp-plugin-login').simpleDDPLogin
const ws = require('isomorphic-ws')
const server = new SimpleDDP({
  endpoint: process.env.STATIC ? 'wss://admin.roseprotein.com/websocket' : 'ws://localhost:4000/websocket',
  // endpoint: 'wss://admin.roseprotein.com/websocket',
  SocketConstructor: ws,
  reconnectInterval: 5000
}, [simpleDDPLogin])

export default async ({ app }) => {
  await server.connect()
  app.$server = server
}

export { server }
