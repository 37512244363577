<script>
import { computed, defineComponent, ref, watch } from 'vue'
import { debounce, useQuasar } from 'quasar'
import { useCart } from 'src/composables/useCart'

export default defineComponent({
  name: 'HoverablePopup',
  props: ['offset', 'identifier', 'visible'],
  emits: ['update:visible'],
  setup (props, { emit }) {
    const popupHovered = ref(false)
    const bthHovered = ref(false)
    const $q = useQuasar()
    const { shoppingCartCount } = useCart()
    const computedVisible = computed({
      get () { return props.visible },
      set (val) { emit('update:visible', val) }
    })

    let checkMenu = () => {
      computedVisible.value = bthHovered.value || popupHovered.value
    }
    watch(bthHovered, () => {
      if ($q.screen.lt.md) {
        return
      }
      checkMenu()
    })
    watch(popupHovered, (val) => {
      checkMenu()
    })

    checkMenu = debounce(checkMenu, 50)

    return {
      shoppingCartCount,
      popupHovered,
      bthHovered,
      computedVisible,
      checkMenu
    }
  }
})
</script>

<template lang="pug">
.menuItem.row.items-center(@click="$q.screen.lt.md ? computedVisible = !computedVisible : ''" @mouseover="$q.screen.gt.sm ? bthHovered = true : null" @mouseout="bthHovered = false").relative-position
  slot(name="button")
  q-popup-proxy.relative-position(no-shake transition-duration="0" :breakpoint="600" :maximized="$q.screen.lt.sm" transition-show="fade" transition-hide="fade" seamless no-parent-event :offset="offset" v-model="computedVisible" no-focus)
    .full-width.popup-wrapper(:style="$q.screen.lt.sm ? `${shoppingCartCount > 0 ? 'height: 85vh' : 'height: auto'}` : `${shoppingCartCount > 0 ? 'max-height: 415px' : 'max-height: 490px'}`" @mouseover="$q.screen.gt.sm ? popupHovered = true : null" @mouseout="popupHovered = false")
      slot(name="popup")
    .blank-space.lt-sm
</template>

<style scoped lang="sass">
.menuItem
  height: 100%
  .q-menu
    background: transparent !important
    box-shadow: none !important
    -moz-box-shadow: none !important
    -o-box-shadow: none !important
    -webkit-box-shadow: none !important
    @media screen and (max-width: 350px)
      width: 300px !important
.popup-wrapper
  box-shadow: none !important
  background: #FFFFFF
  max-width: 100%
  margin-top: 0
  @media screen and (max-width: 599px)
    border-radius: 0
    margin-top: 58px
    position: absolute
    top: 0
    z-index: 2
.blank-space
  height: 300px
  width: 100%
  box-shadow: none !important
  position: absolute
  top: 63% !important
  background: rgba(0, 0, 0, 0.3)
  z-index: 1
</style>
