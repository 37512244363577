<script>
import { defineComponent } from 'vue'
import MainLayout from 'layouts/MainLayout'

export default defineComponent({
  components: { MainLayout }
})
</script>

<template lang="pug">
Suspense
  main-layout
</template>
