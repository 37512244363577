<script setup>
import { onMounted, computed } from 'vue'
import { useLayout } from 'src/composables/useLayout'
import { useAuth } from 'src/composables/useAuth'
import { useRoute, useRouter } from 'vue-router'
import AppHeader from 'layouts/AppHeader'
import PromotionLine from 'components/layout/PromotionLine'
import AuthModal from 'components/auth/AuthModal'
import CartPopup from 'components/cart/CartPopup'
import MobileMenu from 'components/layout/MobileMenu'
import { useCart } from 'src/composables/useCart'
import AppFooter from 'layouts/AppFooter'
import { server } from 'boot/meteor'
import { useQuasar } from 'quasar'

const {
  startAppRest,
  startAppFirstPaint,
  extraMenus,
  showDrawer,
  randomizedDiscount,
  translate,
  appLoaded
} = useLayout()
const { authUserFromToken, showLogin } = useAuth()
const { refreshFullCartItems, shoppingCart, showCartPopup } = useCart()
const route = useRoute()
const router = useRouter()
const $q = useQuasar()

onMounted(async () => {
  try {
    authUserFromToken()
    const shoppingCartLC = JSON.parse(localStorage.getItem('shoppingCart'))
    if (shoppingCartLC && shoppingCartLC.length > 0) {
      shoppingCart.value = shoppingCartLC
      refreshFullCartItems()
    }
    if (route.query.registrationToken) {
      console.log('FOUND REG TOKEN', route.query.registrationToken)
      try {
        await server.call('verifyRegistrationEmail', route.query.registrationToken)
        $q.notify({ type: 'positive', message: translate('auth.emailVerifiedLogin') })
      } catch (e) {
        console.log('ANYAD', e)
        $q.notify({ type: 'negative', message: e.reason })
      }
      router.replace({ query: null })
    }
  } catch (e) {
    console.lo('ANYAD', e)
  }
})

await startAppFirstPaint()
await startAppRest()
</script>

<template lang="pug">
q-layout.q-pa-none.q-ma-none.full-width(view='lhh lpR fff' style="background-color: #0F1319; min-height: 100vh" v-if="appLoaded").bg-white
  #appHeader
  app-header
  auth-modal
  cart-popup
  mobile-menu(v-if="$q.screen.lt.sm")
  q-page-container
    q-page.contentHeight
      promotion-line
      router-view(v-slot='{ Component }')
        suspense
          div
            component(:is='Component')
  app-footer
</template>

<style lang="sass">
.contentHeight
  @media screen and (max-width: 1024px)
    min-height: calc(100vh - 90px) !important
  @media screen and (min-width: 1024px)
    min-height: calc(100vh - 200px) !important
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600&display=swap')
.menu-separator
  background-color: #ECECEC
  margin-left: 10px
.menu-dropdown
  display: flex
  padding-top: 4px
  padding-bottom: 4px
  .q-expansion-item__container
    width: 100%
  i
    color: #4D4F53
    font-size: 30px !important
.home-btn
  .block
    padding-top: 20px !important
.menu-dropdown, .menu-btn
  color: #4D4F53
  border-radius: 0
  font-size: 18px
  width: 100%
  .q-item__label, .block
    padding-left: 12px
  .q-item, .block
    padding-top: 12px
    padding-bottom: 12px
.dark-color
  color: #4D4F53
.red-color
  color: #F0232B !important
.bg1
  background: url("/img/bg-1.png") fixed
  background-size: cover
.font-color
  color: rgb(50, 50, 50)
.text-400
  font-weight: 400
.text-500
  font-weight: 500
.text-600
  font-weight: 600
.flex-1
  flex: 1
.flex-1-1
  flex: 1 1 100%
.square
  border-radius: 0 !important
.btn-bg-grey
  background: #e0e0e0
  border-radius: 5px
.category
  display: grid
  grid-auto-flow: dense
.radius-10
  border-radius: 10px !important
.text-300
  font-weight: 300
.scroll-area > .scroll
  align-items: start !important
.text-ratingSelected
  color: #0471F4
.text-ratingUnSelected
  color: #DEDEDE
.menu-bottom-btn
  padding-left: 16px
  height: 62px
  width: 100%
.contentWidth
  width: 1440px
  max-width: 90%
  padding-left: 16px
  padding-right: 16px
  @media screen and (max-width: 1000px)
    max-width: 95% !important
  @media screen and (max-width: 600px)
    max-width: 100% !important
    padding-left: 10px
    padding-right: 10px
.box-shadow
  box-shadow: 0 2px 2px 0 rgba(223,224,227,1)
  -webkit-box-shadow: 0 2px 2px 0 rgba(223,224,227,1)
  -moz-box-shadow: 0 2px 2px 0 rgba(223,224,227,1)
  -o-box-shadow: 0 2px 2px 0 rgba(223,224,227,1)
.greenGradient
  background: rgb(16,108,235)
  background: -moz-linear-gradient(90deg, rgba(16,108,235,1) 0%, rgba(241,34,42,1) 100%)
  background: -webkit-linear-gradient(90deg, rgba(16,108,235,1) 0%, rgba(241,34,42,1) 100%)
  background: linear-gradient(90deg, rgba(16,108,235,1) 0%, rgba(241,34,42,1) 100%)
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#106ceb",endColorstr="#f1222a",GradientType=1)
body
  font-family: 'Work Sans', sans-serif !important
.isDesktop
  @media screen and (max-width: 1023px)
    display: none !important
.isMobile
  @media screen and (min-width: 1024px)
    display: none

.input-style
  font-size: 17px
  .q-field__control
    height: 60px
    border-radius: 8px
  .q-field__control:before
    border: 1px solid transparent
    box-shadow: 0 2px 2px 0 rgba(223,224,227,1)
    -webkit-box-shadow: 0 2px 2px 0 rgba(223,224,227,1)
    -moz-box-shadow: 0 2px 2px 0 rgba(223,224,227,1)
.input-style > * > .q-field__control
  background: #F2F2F2
  border: none
  padding-bottom: 20px
  @media screen and (max-width: 1023px)
    padding-bottom: 16px !important
  div
    div
      @media screen and (max-width: 1000px)
        border-radius: 5px !important
        font-size: 16px
  .q-placeholder, .q-field__label
    font-size: 16px !important
    color: #4D4F53 !important
  .q-field--outlined, .q-field__control:before
    border: 0 !important
  .q-field__control
    padding-left: 20px !important
    box-shadow: 0 2px 2px 0 rgba(223,224,227,1)
    -webkit-box-shadow: 0 2px 2px 0 rgba(223,224,227,1)
    -moz-box-shadow: 0 2px 2px 0 rgba(223,224,227,1)
    -o-box-shadow: 0 2px 2px 0 rgba(223,224,227,1)

::-webkit-scrollbar
  border-radius: 8px
  background-color: #CDE2FD
  width: 12px
  opacity: 0.75

/* Track */
::-webkit-scrollbar-track
  box-shadow: inset 0 0 5px grey
  border-radius: 10px

/* Handle */
::-webkit-scrollbar-thumb
  border-radius: 8px
  background-color: #2D5ED9
  width: 8px
  opacity: 0.75

.text-brandblue
  color: #0370f7
.bg-brandblue
  background-color: #0370f7
</style>
