<script>
import { useLayout } from 'src/composables/useLayout'
import { useCart } from 'src/composables/useCart'
import { useAuth } from 'src/composables/useAuth'
import { useFilters } from 'src/composables/useFilters'
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import PopupItem from 'components/product/PopupItem'
import ProductSkeleton from 'components/product/ProductSkeleton'

export default {
  name: 'SmallCart',
  components: { ProductSkeleton, PopupItem },
  emits: ['clicked'],
  setup (props, { emit }) {
    const { translate, thumbStyle, barStyle } = useLayout()
    const { showLogin } = useAuth()
    const { prettifyCurrency } = useFilters()
    const { itemsCountWithGiftsIgnoreQuantity, onlyCartItemsAmount, fullShoppingCart, shoppingCartCount } = useCart()
    const route = useRoute()
    const router = useRouter()
    const cartHeight = computed(() => {
      const res = itemsCountWithGiftsIgnoreQuantity.value * 110
      return res > 400 ? 400 : res
    })

    function popupClicked () {
      if (route.path === '/kosar') {
        emit('clicked')
      } else {
        router.push('/kosar')
        showLogin.value = false
        emit('clicked')
      }
    }
    const openProducts = (route) => {
      if (route !== '/termekek/osszes') {
        router.push('/termekek/osszes')
      }
      emit('clicked')
    }
    return {
      route,
      openProducts,
      translate,
      cartHeight,
      popupClicked,
      shoppingCartCount,
      onlyCartItemsAmount,
      fullShoppingCart,
      prettifyCurrency,
      barStyle,
      thumbStyle
    }
  }
}
</script>

<template lang="pug">
.smallCartContainer
  .lt-sm.small-cart-title {{translate('cart.cartContent')}}:
  .small-cart-wrapper
    .row.q-mr-sm.items-center
      svg.cart-icon.q-mr-sm(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24' fill="#e02838")
        path(fill='none' d='M0 0h24v24H0z')
        path(d='M7 8V6a5 5 0 1 1 10 0v2h3a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h3zm0 2H5v10h14V10h-2v2h-2v-2H9v2H7v-2zm2-2h6V6a3 3 0 0 0-6 0v2z')
      div
        .text-400.dark-color(:class="shoppingCartCount > 0 ? 'text-lowercase' : ''") {{shoppingCartCount > 0  ? shoppingCartCount + ' ' + translate('product.product') : translate('cart.yourCartIsEmpty')}}
        .small-cart-total {{ prettifyCurrency(onlyCartItemsAmount, 0) }}
    .row.items-center.no-wrap
      q-btn.small-cart-btn(v-if="shoppingCartCount > 0" @click="popupClicked" flat :label="translate('product.order')")
      .close-btn.lt-sm(@click="$emit('clicked')")
        svg(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='30' height='30' fill="#4D4F53")
          path(fill='none' d='M0 0h24v24H0z')
          path(d='M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z')
  q-separator.full-width(size="1px")
  .flex.q-pa-none(v-if="shoppingCartCount > 0")
    q-scroll-area.small-cart-scroll-area(:thumb-style="thumbStyle" :bar-style="barStyle" :style="`height:${$q.screen.lt.sm ? `${$q.screen.width > 320 ? 'calc(85vh - 122px)' : 'calc(85vh - 122px)'}` : cartHeight+'px'};`")
      popup-item(v-for="(item, index) in fullShoppingCart" @clicked="$emit('clicked')" :cart-item="item" :item-index="index" :cart-length="fullShoppingCart.length"  :key="item._id" :small-cart-item="true" :show-remove="true")
  .skeleton-list(v-else-if="shoppingCartCount < 1")
    .q-px-md.q-pt-md
      product-skeleton(:imgSize="$q.screen.gt.sm ? '70px' : '90px'" textHeight="22px" v-for="skeleton in $q.screen.width > 320 ? 3 : 2")
    q-btn.purchase-now(@click="openProducts(route.path)" :label="translate('cart.purchaseNow') + '!'" no-caps flat)
</template>

<style lang="sass">
.small-cart
  border: 1px solid #e5e6e9
  box-shadow: none !important
  -moz-box-shadow: none !important
  -o-box-shadow: none !important
  -webkit-box-shadow: none !important
.smallCartContainer
  .skeleton-list
    display: flex
    flex-direction: column
    justify-content: space-between
    @media screen and (min-width: 600px)
      min-width: 400px
  .purchase-now
    color: white
    font-weight: 600
    font-size: 20px
    width: 100%
    height: 75px
    border-radius: 0
    background: rgba(16,108,235,1)
    background: -moz-linear-gradient(90deg, rgba(16,108,235,1) 0%, rgba(241,34,42,1) 100%)
    background: -webkit-linear-gradient(90deg, rgba(16,108,235,1) 0%, rgba(241,34,42,1) 100%)
    background: linear-gradient(90deg, rgba(16,108,235,1) 0%, rgba(241,34,42,1) 100%)
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#106ceb",endColorstr="#f1222a",GradientType=1)
  .small-cart-title
    display: flex
    flex-direction: row
    padding: 0 16px
    width: 100%
    align-items: center
    height: 40px
    background: #ECECEE
    text-transform: uppercase
    font-weight: 600
    color: #ABABAD
    font-size: 14px
  .small-cart-scroll-area
    width: 400px
    @media screen and (max-width: 599px)
      width: 100vw !important
    @media screen and (min-width: 600px)
      max-height: 330px
  @media screen and (max-width: 1023px)
    height: 100%
  @media screen and (max-width: 599px)
    width: 100% !important
    background: white
  .small-cart-wrapper
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between
    padding: 16px
    height: 80px
    @media screen and (max-width: 599px)
      padding: 16px 10px !important
    .small-cart-total
      font-weight: 600
      color: #4D4F53
      font-size: 20px
      @media screen and (max-width: 320px)
        font-size: 16px !important
  .close-btn
    display: flex
    cursor: pointer
    border: solid #E4E4E4 2px
    border-radius: 7px
    padding: 8px
    margin-left: 6px
  .small-cart-btn
    height: 50px
    padding: 10px
    color: white
    font-weight: 600
    font-size: 15px
    border-radius: 7px
    background: #0370F7
    @media screen and (max-width: 599px)
      padding: 14px 14px
      font-size: 13px !important
      background: #0370F7 !important
      background: -moz-linear-gradient(90deg, rgba(16,108,235,1) 0%, rgba(241,34,42,1) 100%) !important
      background: -webkit-linear-gradient(90deg, rgba(16,108,235,1) 0%, rgba(241,34,42,1) 100%) !important
      background: linear-gradient(90deg, rgba(16,108,235,1) 0%, rgba(241,34,42,1) 100%) !important
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#106ceb",endColorstr="#f1222a",GradientType=1) !important
</style>
