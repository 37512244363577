<script setup>
import { computed } from 'vue'

const props = defineProps(['suggestion', 'searchText'])
const highlight = computed(() => {
  if (props.searchText) {
    const reg = new RegExp(props.searchText, 'gi')
    return props.suggestion.name.replace(reg, function (str) {
      return '<b>' + str + '</b>'
    })
  }
  return props.suggestion.name
})
</script>

<template lang="pug">
div
  q-item.text-500.font-color.flex.items-center(
    clickable
    dense
    @click="$emit('update:searchText', suggestion.name)"
    style="font-size: 0.9em; padding-left: 0px !important; padding-left: 5px;"
  ).gt-sm
    div(v-html="highlight" )
  q-chip.text-500.font-color.flex.items-center(
    clickable
    dense
    @click="$emit('update:searchText', suggestion.name)"
    style="font-size: 0.9em; padding-left: 0px !important; padding-left: 5px;"
  ).lt-md.q-mt-sm
    div(v-html="highlight" )
</template>

<style scoped lang="sass">

</style>
