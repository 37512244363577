import { reactive, toRefs } from 'vue'
import { server } from 'boot/meteor'
import { useLayout } from 'src/composables/useLayout'
import { Notify } from 'quasar'
const { translate } = useLayout()

const originalState = {
  userId: null,
  email: null,
  fullName: null,
  roles: [],
  banned: null,
  avatar: null,
  userDetailsLoaded: false,
  isLoggedIn: false,
  showLogin: false,
  showRegister: false,
  showForgottenPassword: false,
  lang: null,
  phonenumber: null,
  zipcode: null,
  city: null,
  userCountry: null,
  street: null,
  company: null,
  taxnumber: null,
  showNoPasswordSet: false,
  authTab: 'login'
}

const state = reactive(originalState)

const logout = async () => {
  // await router.push('/')
  if (process.env.SERVER) { return }
  localStorage.removeItem('auth_token')
  state.userDetailsLoaded = false
  state.isLoggedIn = false
  await server.logout()
  Notify.create({ type: 'positive', message: translate('profile.loggedOut') })
}

const setUser = (user) => {
  state.fullName = user.fullName
  state.email = user.emails[0].address
  state.roles = user.roles
  state.banned = user.banned
  state.avatar = user.avatar
  state.phonenumber = user.phonenumber
  state.zipcode = user.zipcode
  state.city = user.city
  state.userCountry = user.country
  state.street = user.street
  state.company = user.company
  state.taxnumber = user.taxnumber
  console.log('USER TO SET', user)
  if (!state.userDetailsLoaded) {
    state.userDetailsLoaded = true
  }
}

export function useAuth () {
  const authUserWithEmailAndPassword = async ({ email, password }) => {
    try {
      await server.login({ password, user: { email } })
      localStorage.setItem('auth_token', server.token)
      const userSub = server.subscribe('loggedInUser')
      await userSub.ready()
      const user = server.collection('users').fetch().filter(user => user.id === server.userId)[0]
      state.userId = server.userId
      if (user) {
        if (user.banned || user.removed) {
          logout()
        } else {
          setUser(user)
        }
      }
      state.showLogin = false
      redirectAfterLogin()
    } catch (e) {
      console.log('ERROR', e)
      if (e.reason.reason === 'User has no password set') {
        state.showNoPasswordSet = true
      } else if (e.reason.reason === 'Incorrect password') {
        Notify.create({ type: 'info', message: translate(`error.${e.reason.reason}`) })
      } else if (e.reason === 'EMAIL_NOT_VERIFIED') {
        Notify.create({ type: 'info', message: translate('error.EMAIL_NOT_VERIFIED') })
      } else {
        Notify.create({ type: 'info', message: e.reason })
      }
    }
  }

  function redirectAfterLogin () {
    Notify.create({ type: 'positive', message: translate('auth.successfulLogin') })
    state.showLogin = false
  }

  const authUserFromToken = async () => {
    const authToken = localStorage.getItem('auth_token')
    if (authToken) {
      try {
        const res = await server.login({ resume: authToken })
        state.isLoggedIn = !!res
        if (state.isLoggedIn) {
          window.onbeforeunload = async function () {
            await server.call('windowClosed')
          }
        }
      } catch (e) {
        console.log('ASD', e)
        if (e.error === '403') {
          await logout()
        }
      }
      const userSub = server.subscribe('loggedInUser')
      await userSub.ready()
      const user = server.collection('users').fetch().filter(user => user.id === server.userId)[0]
      state.userId = server.userId
      if (user) {
        if (user.banned || user.removed) {
          await logout()
        } else {
          setUser(user)
        }
      }
    }
  }

  return {
    ...toRefs(state),
    logout,
    setUser,
    authUserFromToken,
    authUserWithEmailAndPassword
  }
}
